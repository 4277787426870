import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import ArticleContext from './ArticleContext'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import ArticlesPage from './ArticlesPage'

function ArticlesRouting() {
  const intl = useIntl()
  const {pathname} = useLocation()
  const [SideDrawerId, updateId] = useState('0')
  const UpdatingId = (id) => {
    updateId(id)
  }
  const candidateAttachments = [
    {
      title: 'Manage Articles-',
      path: `/discover/manage-topics`,
      isSeparator: false,
      isActive: false,
    },
  ]
  const switchMethod = () => {
    switch (pathname) {
      case `/discover/manage-topics`:
        return (
          <>
            <PageTitle breadcrumbs={candidateAttachments}>Articles</PageTitle>
            <ArticlesPage />
          </>
        )
      case `/discover/add-edit-articles`:
        return (
          <>
            <PageTitle breadcrumbs={candidateAttachments}>Articles</PageTitle>
            <ArticlesPage />
          </>
        )

      default:
        return (
          <>
            <ArticlesPage />
          </>
        )
    }
  }

  return (
    <>
      <ArticleContext.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        {/* <ArticlesPage /> */}
        {switchMethod()}
      </ArticleContext.Provider>
    </>
  )
}

export default ArticlesRouting
