//businessCard
import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'

import usePostApi from '../../custom_hooks/usePostApi'

const ViewArticleDrawer = (props) => {
  console.log(props)
  const intl = useIntl()
  const articleId = props.props.id
  console.log(articleId)
  const {execute} = usePostApi()
  const [articlesData, setarticlesData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const getArticles = async (show) => {
    try {
      const url = `/articles/get`
      const formData = {
        article_id: articleId,
      }

      const response = await execute(url, 'POST', formData)

      setarticlesData(response.data)
      console.log(response.data)
      setRefresh(false)
    } catch (error) {
      console.error('Error:', error)
      setRefresh(false)
    }
  }

  useEffect(() => {
    getArticles()
  }, [])

  let articlesdata = articlesData && articlesData[0]

  return (
    <div>
      <div className='card shadow-none rounded-0'>
        <>
          <div className='card-header' id='side_activities_header'>
            <h3 className='card-title fw-bolder text-dark'>Article Info</h3>
            <div className='card-toolbar '>
              <React.Fragment>
                <div className='btn btn-icon btn-sm btn-active-danger' id='side_activities_close'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </React.Fragment>
            </div>
          </div>
          <div className='card-body position-relative' id='side_activities_body'>
            <div>
              <div
                id='side_activities_scroll'
                className='position-relative scroll-y me-n5 pe-5 w-400px'
                data-kt-scroll='true'
                data-kt-scroll-height='auto'
                data-kt-scroll-wrappers='#side_activities_body'
                data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                data-kt-scroll-offset='5px'
              >
                <div>
                  {/* {isLoading ? (
                    <div className='card h-350px'>
                      <div className='m-auto d-flex flex-column align-items-center'>
                        <div className='spinner-border spinner-primary mr-15'></div>
                      </div>
                    </div>
                  ) : ( */}
                  <>
                    <>
                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>Title (Arabic)</label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.title_ar}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>
                          Title Phonetic (Arabic)
                        </label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.title_ar_phonetic}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>Title (English)</label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.title_en}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>
                          Title Phonetic (English)
                        </label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.title_en_phonetic}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>Content (Arabic)</label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.content_ar}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>
                          Content Phonetic (Arabic)
                        </label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.content_ar_phonetic}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>Content (English)</label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.content_en}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>
                          Content Phonetic (English)
                        </label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.content_en_phonetic}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>Media type</label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.media_type}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>Voice type (Arabic)</label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.voice_ar_type}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>Voice type (English)</label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.voice_en_type}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>Created Date</label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.start_ts}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>End Date</label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.end_ts}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>Tags</label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.tags}
                          </span>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <label className='col-lg-4 fw-bold text-muted'>Status</label>
                        <div className='col-lg-8'>
                          <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                            {props.props.status === 1 ? 'Active' : 'Inactive'}
                          </span>
                        </div>
                      </div>
                    </>
                  </>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer text-end' id='side_activities_footer'>
            <button
              className='btn btn-sm btn-secondary m-2'
              type='button'
              id='side_activities_close'
            >
              Close
            </button>
          </div>
        </>
      </div>
    </div>
  )
}

export {ViewArticleDrawer}
