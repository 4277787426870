import React, {useState, useEffect, useRef, useMemo} from 'react'
import '../index.css'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useIntl, FormattedMessage} from 'react-intl'
import UploadVideo from './UploadVideo'
import usePostApi from '../../../custom_hooks/usePostApi'

// Component to render a single YouTube video

const VideoItem = ({vid}) => {
  const navigate = useNavigate()

  const getYouTubeVideoId = (url) => {
    const videoIdMatch = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.*v=))([\w-]{11})/
    )
    return videoIdMatch ? videoIdMatch[1] : null
  }

  const videoId = getYouTubeVideoId(vid?.media_location_url)

  return (
    <div className='video-item- w-100 w-md-50 w-lg-25 mb-5 mb-md-0'>
      {/* {!isPlaying ? ( */}
      <div
        className='p-md-2'
        onClick={() => {
          navigate('/media/video/details', {
            state: {
              videoUrl: vid?.media_location_url,
              media_id: vid?.media_id,
            },
          })
        }}
      >
        <img
          src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
          alt='YouTube Thumbnail'
          style={{cursor: 'pointer', width: '100%', height: 'auto'}}
        />

        <div className='card p-2' style={{height: '60px'}}>
          <div className='d-flex flex-row justify-content-between'>
            <p className='m-0 w-75'>
              <span className='d-inline-block text-truncate' style={{maxWidth: '100%'}}>
                {vid?.media_title_ar} {vid?.media_title_ar} {vid?.media_title_ar}
              </span>
              <br />
              {/* {vid?.media_title_en && <span className='m-0'>({vid?.media_title_en})</span>} */}
              <span className='d-inline-block text-truncate' style={{maxWidth: '100%'}}>
                {vid?.media_desc_ar}
              </span>
              {/* <br />
              {vid?.media_desc_en && <span className='m-0'>({vid?.media_desc_en})</span>} */}
            </p>
            <div>
              <p
                className={`badge badge-light-${
                  vid.status === 1 ? 'success' : 'danger'
                } fw-normal m-0`}
              >
                {vid.status === 1 ? 'Active' : 'Inactive'}
              </p>
              <br />
              <span className='fs-8'>{vid?.created_ts?.slice(0, 11)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Component to render multiple YouTube videos
const YouTubeVideos = () => {
  const intl = useIntl()
  const {execute, loading} = usePostApi()
  const [showAddModal, setShowAddModal] = useState(false)
  const [vidsData, setvidsData] = useState([])
  const [inputVal, setInputVal] = useState('')
  const fetchData = async () => {
    try {
      const url = `/media/get/all`
      const formData = {media_type: 'V'}
      const response = await execute(url, 'POST', formData)
      setvidsData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  // console.log(vidsData)

  const filteredData = useMemo(() => {
    return vidsData
      ? vidsData.filter((each) => {
          const titleAr =
            each.media_title_ar &&
            each.media_title_ar.toLowerCase().includes(inputVal.toLowerCase())
          const titleEn =
            each.media_title_en &&
            each.media_title_en.toLowerCase().includes(inputVal.toLowerCase())
          const descAr =
            each.media_desc_ar && each.media_desc_ar.toLowerCase().includes(inputVal.toLowerCase())
          const descEn =
            each.media_desc_en && each.media_desc_en.toLowerCase().includes(inputVal.toLowerCase())

          return titleAr || titleEn || descAr || descEn
        })
      : []
  }, [inputVal, vidsData])

  return (
    <>
      <UploadVideo
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        type='add'
        data={{}}
        loadData={fetchData}
      />
      {loading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : vidsData?.length > 0 ? (
        <>
          <div className='d-flex flex-wrap flex-stack mb-2'>
            <h3 className='fw-semibold default-cursor'>
              Attachments
              <span className='fs-6 text-gray-400 fw-normal ms-2'>{`Resources ${vidsData?.length}`}</span>
            </h3>

            <div className='d-flex my-2'>
              <button
                className={`d-none btn btn-icon btn-light-primary btn-sm`}
                // onClick={() => setView('table')}
                data-toggle='tooltip'
                title='Table View'
              >
                <KTIcon iconName='text-align-justify-center' className='fs-3' />
              </button>
              <button
                className={`d-none btn btn-icon btn-light-primary btn-sm ms-4`}
                // onClick={() => setView('grid')}
                data-toggle='tooltip'
                title='Grid View'
              >
                <KTIcon iconName='element-2' className='fs-3' />
              </button>
              <div className='d-flex align-items-center position-relative ms-4'>
                <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                <input
                  type='search'
                  id='kt_filter_search'
                  className='form-control form-control-white form-control-sm w-150px ps-9'
                  placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.SEARCH'})}
                  value={inputVal}
                  onChange={(e) => setInputVal(e.target.value)}
                />
              </div>
              <button
                className='btn btn-sm btn-light-primary ms-4'
                onClick={() => setShowAddModal(true)}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Upload
              </button>
            </div>
          </div>

          {filteredData.length === 0 ? (
            <div className='card h-250px d-flex justify-content-center align-items-center'>
              <h3 className='fw-semibold'>
                <FormattedMessage
                  id='MSG.NO_DATA_MATCH'
                  values={{
                    inputVal: <span className='text-primary'>{inputVal}</span>,
                  }}
                />
              </h3>
            </div>
          ) : (
            <div className='video-contai-ner d-flex flex-wrap'>
              {filteredData?.map((vid) => {
                return <VideoItem key={vid.media_id} vid={vid} />
              })}
            </div>
          )}
        </>
      ) : (
        <div className='card h-250px d-flex flex-column justify-content-center align-items-center'>
          <h3 className='fw-semibold'>No data found.</h3>
          <button className='btn btn-light-primary btn-sm' onClick={() => setShowAddModal(true)}>
            <KTIcon iconName='plus' className='fs-3' />
            Upload
          </button>
        </div>
      )}
    </>
  )
}

export default YouTubeVideos
