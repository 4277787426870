import React from 'react'
import {Route, Routes, useLocation} from 'react-router-dom'
import Card from './components/Card'
import ImageView from './components/image/ImageView'
import ImageDetails from './components/image/ImageDetails'
import VideoMain from './components/video/VideoMain'
import VideoDetails from './components/video/VideoDetails'
import AudioMain from './components/audio/AudioMain'
import AudioDetails from './components/audio/AudioDetails'
import GalleryMain from './components/gallery_slider/GalleryMain'
import GalleryDetails from './components/gallery_slider/GalleryDetails'

function MediaRoute() {
  const location = useLocation()

  // Check if the current path includes '/image/details'
  const showCard =
    !location.pathname.includes('/image/details') &&
    !location.pathname.includes('/video/details') &&
    !location.pathname.includes('/audio/details') &&
    !location.pathname.includes('/gallery-slider/details')

  const getTitle = () => {
    if (location.pathname.includes('/media/image') || location.pathname === '/media') {
      return 'Manage Images'
    } else if (location.pathname === '/media/audio') {
      return 'Manage Audios'
    } else if (location.pathname === '/media/video') {
      return 'Manage Videos'
    } else if (location.pathname === '/media/gallery-slider') {
      return 'Manage Gallery/Sliders'
    }
  }

  const getDescription = () => {
    if (location.pathname.includes('/image') || location.pathname === '/media') {
      return 'Easily upload, manage, and update images. Ensure that all visuals are up to date and organized for easy access and use throughout the platform.'
    } else if (location.pathname === '/media/audio') {
      return 'Upload and manage audio files with ease. Keep track of all audio assets, update, or replace as needed for consistent sound experiences across the platform.'
    } else if (location.pathname === '/media/video') {
      return 'Seamlessly manage video content. Upload new videos, update existing ones, and ensure all multimedia is organized and ready for use across the platform.'
    } else if (location.pathname === '/media/gallery-slider') {
      return ' Manage your image galleries and sliders effortlessly. Organize your media, update existing galleries, or create new sliders to showcase content in the most engaging way.'
    }
  }
  return (
    <>
      {showCard && (
        <>
          <div className='d-flex flex-wrap flex-stack'>
            <div className='d-flex'>
              <h1 className='fs-3 fw-semibold m-0'>{getTitle()}</h1>
            </div>
            <div className='d-flex'>
              <div className='m-auto d-flex align-items-center'>
                <button
                  className='btn btn-sm btn-light fw-normal'
                  // onClick={() => setShowKM(true)}
                >
                  <i className='ki-duotone ki-information-3 fs-3'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>{' '}
                  Know More
                </button>
              </div>
            </div>
          </div>

          <p className='fs-6 text-gray-600 mb-4'>{getDescription()}</p>
        </>
      )}
      {showCard && <Card />}
      <Routes>
        <Route path='/image' element={<ImageView />} />
        <Route path='/audio' element={<AudioMain />} />
        <Route path='/video' element={<VideoMain />} />
        <Route path='/gallery-slider' element={<GalleryMain />} />
        <Route path='/image/details' element={<ImageDetails />} />
        <Route path='/audio/details' element={<AudioDetails />} />
        <Route path='/video/details' element={<VideoDetails />} />
        <Route path='/gallery-slider/details' element={<GalleryDetails />} />
        <Route index element={<ImageView />} />
      </Routes>
    </>
  )
}

export default MediaRoute
