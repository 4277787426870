import {useMemo, useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import {useIntl, FormattedMessage} from 'react-intl'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import usePostApi from '../../custom_hooks/usePostApi'
import {PageTitle} from '../../../../_metronic/layout/core'
// import AddNewAttachment from './AddNewAttachment'
import AddNewRule from './AddNewRule'

const DataTable = ({columns, data, loading, fetchData}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const {state} = location
  const [view, setView] = useState('table')
  const [showAddModal, setShowAddModal] = useState(false)

  useEffect(() => {
    if (state?.view) {
      setView(state?.view)
    }
  }, [state?.view])

  const [inputVal, setInputVal] = useState('')

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const titleAr =
            each.media_title_ar &&
            each.media_title_ar.toLowerCase().includes(inputVal.toLowerCase())
          const titleEn =
            each.media_title_en &&
            each.media_title_en.toLowerCase().includes(inputVal.toLowerCase())
          const rulersTitleAr =
            each.ruler_title_ar &&
            each.ruler_title_ar.toLowerCase().includes(inputVal.toLowerCase())
          const rulersTitleEn =
            each.ruler_title_en &&
            each.ruler_title_en.toLowerCase().includes(inputVal.toLowerCase())
          const rilersMsgAr =
            each.ruler_message_ar &&
            each.ruler_message_ar.toLowerCase().includes(inputVal.toLowerCase())
          const rulersMsgEn =
            each.ruler_message_en &&
            each.ruler_message_en.toLowerCase().includes(inputVal.toLowerCase())

          return titleAr || titleEn || rulersTitleAr || rulersTitleEn || rilersMsgAr || rulersMsgEn
        })
      : []
  }, [inputVal, data])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]
  const count = data?.length

  const RulersMsgBC = [
    {
      title: `Administration`,
      path: `/administration`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `Administration`,
      path: `/administration`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Ruler's Messages`,
      path: `/administration/rulers-message`,
      isSeparator: false,
      isActive: false,
    },
  ]

  if (loading) {
    return (
      <div className='card h-250px'>
        <div className='m-auto d-flex flex-column align-items-center'>
          <div className='spinner-border spinner-primary mr-15'></div>
        </div>
      </div>
    )
  } else if (!data || !data.length) {
    return (
      <>
        <AddNewRule
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          type='add'
          data={{}}
          loadData={fetchData}
        />
        <div className='card h-250px d-flex flex-column justify-content-center align-items-center'>
          <h3 className='fw-semibold'>No data found.</h3>
          <button className='btn btn-sm btn-light-primary' onClick={() => setShowAddModal(true)}>
            <KTIcon iconName='plus' className='fs-3' />
            Upload
          </button>
        </div>
      </>
    )
  } else {
    return (
      <>
        <PageTitle breadcrumbs={RulersMsgBC}>Ruler's Messages</PageTitle>
        <AddNewRule
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          type='add'
          data={{}}
          loadData={fetchData}
        />

        {loading ? (
          <div className='card h-250px'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : (
          <>
            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex'>
                <h1 className='fs-3 fw-semibold m-0'>Manage Ruler's Messages</h1>
              </div>
              <div className='d-flex'>
                <div className='m-auto d-flex align-items-center'>
                  <button
                    className='btn btn-sm btn-light fw-normal'
                    // onClick={() => setShowKM(true)}
                  >
                    <i className='ki-duotone ki-information-3 fs-3'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>{' '}
                    Know More
                  </button>
                </div>
              </div>
            </div>
            <p className='fs-6 text-gray-600 mb-4'>
              View, update, and manage the Ruler's Messages seamlessly. Easily update existing
              messages or add new ones to ensure all important communications are up to date and
              accessible.
            </p>

            <div
              className='card mb-0 mb-xl-8 overflow-auto'
              style={{minHeight: data?.length <= 1 ? '149px' : ''}}
            >
              <div className='p-10 pt-5 pb-5'>
                <div className='d-flex flex-wrap flex-stack mb-5'>
                  <h3 className='fw-semibold default-cursor'>
                    Attachments
                    <span className='fs-6 text-gray-400 fw-normal ms-2'>{`Resources ${count}`}</span>
                  </h3>

                  <div className='d-flex align-items-center'>
                    <div className='d-flex align-items-center position-relative'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9'
                        placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.SEARCH'})}
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                    </div>
                    <button
                      className='btn btn-sm btn-light-primary ms-4'
                      onClick={() => setShowAddModal(true)}
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      Add
                    </button>
                  </div>
                </div>
                <table
                  className='table table-row-dashed table-row-gray-300 align-middle'
                  {...getTableProps()}
                >
                  <thead>
                    <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                      <th className='min-w-200px text-start ps-3'>Ruler's Image & Media Title</th>
                      <th className='min-w-200px text-start'>Ruler's Title</th>
                      <th className='min-w-200px text-start'>Ruler's Message</th>

                      {/* <th className='min-w-150px text-start'>Author</th> */}
                      {/* <th className='min-w-150px text-start'>Metadata</th> */}
                      {/* <th className='min-w-100px text-start'>Status</th> */}
                      <th className='min-w-100px text-center'>
                        {intl.formatMessage({id: 'MENU.ACTIONS'})}
                      </th>
                    </tr>
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page?.length > 0 ? (
                      page.map((row) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td className='text-center' {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td className='' colSpan={4}>
                          <div className='d-flex justify-content-center'>
                            <p className='text-dark default-cursor fs-6 mt-5'>
                              {inputVal === '' ? (
                                intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                              ) : (
                                <FormattedMessage
                                  id='MSG.NO_DATA_MATCH'
                                  values={{
                                    inputVal: <span className='text-primary'>{inputVal}</span>,
                                  }}
                                />
                              )}
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {data?.length > 10 && (
          <Pagination>
            <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
            <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

            {[...Array(pageCount)].map((_, i) => (
              <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                {i + 1}
              </Pagination.Item>
            ))}

            <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
            <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />

            <div className='d-flex align-items-center mx-5'>
              <span className='me-2'>{intl.formatMessage({id: 'BTN.SHOW'})}</span>
              <select
                className='form-select form-select-solid fw-semibold w-75px me-2'
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {pageOptions.map((option) => (
                  <option key={option} value={option} className='select-bg'>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </Pagination>
        )}
      </>
    )
  }
}

const RulersMessagesTB = () => {
  const navigate = useNavigate()
  const {execute, loading} = usePostApi()
  const [imgsData, setImgsData] = useState([])
  const fetchData = async () => {
    try {
      const url = `/ruler/get/all`
      const formData = {media_type: 'I'}
      const response = await execute(url, 'POST', formData)
      setImgsData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'img',
        Cell: ({row}) => {
          return (
            <div
              className='min-w-170px text-start ps-3 cursor-default'
              //   onClick={() =>
              //     navigate('/media/image/details', {
              //       state: {
              //         img_url: row.original.media_location_url,
              //         title: row.original.media_title_en || row.original.media_title_ar,
              //         view: 'table',
              //         media_id: row.original.media_id,
              //       },
              //     })
              //   }
            >
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-45px me-5'>
                  <img src={row.original?.ruler_photo} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <p className='text-dark fw-semibold text-hover-primary fs-6 m-0'>
                    {row.original.media_title_ar}
                    <br />
                    {row.original?.media_title_en && `(${row.original.media_title_en})`}
                  </p>
                </div>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'MediaTitle',
        accessor: 'mediaTitle',
        Cell: ({row}) => {
          return (
            <p
              className={`text-dark text-hover-primary d-block fs-6 text-start cursor-default`}
              //   onClick={() =>
              //     navigate('/media/image/details', {
              //       state: {
              //         img_url: row.original.media_location_url,
              //         title: row.original.media_title_en || row.original.media_title_ar,
              //         view: 'table',
              //         media_id: row.original.media_id,
              //       },
              //     })
              //   }
            >
              {row.original?.ruler_title_ar}
              <br />
              {row.original?.ruler_title_en && `(${row.original.ruler_title_en})`}
            </p>
          )
        },
      },
      {
        Header: 'RulersMessage',
        accessor: 'rulersMessage',
        Cell: ({row}) => {
          return (
            <p
              className={`text-dark text-hover-primary d-block fs-6 text-start cursor-default`}
              //   onClick={() =>
              //     navigate('/media/image/details', {
              //       state: {
              //         img_url: row.original.media_location_url,
              //         title: row.original.media_title_en || row.original.media_title_ar,
              //         view: 'table',
              //         media_id: row.original.media_id,
              //       },
              //     })
              //   }
            >
              {row.original?.ruler_message_ar}
              <br />
              {row.original?.ruler_message_en && `(${row.original.ruler_message_en})`}
            </p>
          )
        },
      },

      //   {
      //     Header: 'Author',
      //     accessor: 'author',
      //     Cell: ({row}) => {
      //       const metaData = JSON.parse(row.original?.metadata)
      //       const metaInfo = JSON.parse(metaData?.meta_info)

      //       return (
      //         <p
      //           className={`text-dark text-hover-primary d-block fs-6 text-start cursor-pointer`}
      //           onClick={() =>
      //             navigate('/media/image/details', {
      //               state: {
      //                 img_url: row.original.media_location_url,
      //                 title: row.original.media_title_en || row.original.media_title_ar,
      //                 view: 'table',
      //                 media_id: row.original.media_id,
      //               },
      //             })
      //           }
      //         >
      //           {metaInfo?.author}
      //         </p>
      //       )
      //     },
      //   },
      //   {
      //     Header: 'MetaData',
      //     accessor: 'metaData',
      //     Cell: ({row}) => {
      //       const metaData = JSON.parse(row.original.metadata)

      //       function bytesToSize(bytes) {
      //         const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      //         if (bytes === 0) return 'n/a'
      //         const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      //         if (i === 0) return `${bytes} ${sizes[i]})`
      //         return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
      //       }
      //       return (
      //         <p
      //           className={`text-dark text-hover-primary default-cursor d-block fs-6 text-start cursor-pointer`}
      //           onClick={() =>
      //             navigate('/media/image/details', {
      //               state: {
      //                 img_url: row.original.media_location_url,
      //                 title: row.original.media_title_en || row.original.media_title_ar,
      //                 view: 'table',
      //                 media_id: row.original.media_id,
      //               },
      //             })
      //           }
      //         >
      //           {metaData?.extension}
      //           <br />
      //           {bytesToSize(metaData?.size)}
      //         </p>
      //       )
      //     },
      //   },
      //   {
      //     Header: 'Status',
      //     accessor: 'status',
      //     Cell: ({row}) => {
      //       return (
      //         <div className='text-start'>
      //           <p
      //             className={`fw-normal default-cursor badge badge-light-${
      //               row.original.status === 1 ? 'success' : 'danger'
      //             }`}
      //           >
      //             {row.original?.status === 1 ? 'Active' : 'Inactive'}
      //           </p>
      //         </div>
      //       )
      //     },
      //   },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({row}) => {
          return (
            <button
              className='btn btn-light-primary btn-sm'
              onClick={() =>
                navigate('/administration/rulers-message/details', {
                  state: {
                    ruler_id: row.original.ruler_id,
                  },
                })
              }
            >
              View
            </button>
          )
          return (
            <>
              {row.original?.media_location_url ? (
                <div className='text-center'>
                  <button
                    className='btn btn-icon btn-light-primary btn-sm ms-3'
                    onClick={() => {
                      if (row.original?.media_location_url) {
                        window.open(row.original?.media_location_url, '_blank')
                      } else {
                        alert('Not Found')
                      }
                    }}
                    data-toggle='tooltip'
                    title='View document'
                  >
                    <KTIcon iconName='document' className='fs-3' />
                  </button>

                  <button
                    className='btn btn-icon btn-light-primary btn-sm ms-3'
                    onClick={() =>
                      navigate('/media/image/details', {
                        state: {
                          img_url: row.original.media_location_url,
                          title: row.original.media_title_en || row.original.media_title_ar,
                          view: 'table',
                          media_id: row.original.media_id,
                        },
                      })
                    }
                    data-toggle='tooltip'
                    title='Download document'
                  >
                    <KTIcon iconName='pencil' className='fs-3' />
                  </button>
                </div>
              ) : (
                <p
                  className={`text-center text-dark text-hover-primary default-cursor d-block fs-6 text-start`}
                >
                  -
                </p>
              )}
            </>
          )
        },
      },
    ],
    []
  )

  return <DataTable columns={columns} data={imgsData} loading={loading} fetchData={fetchData} />
}

export default RulersMessagesTB
