import React, {useState, useEffect, useMemo} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import UploadGallery from './UploadGallery'
import usePostApi from '../../../custom_hooks/usePostApi'
import {useIntl, FormattedMessage} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../../../_metronic/layout/core'

function GalleryMain() {
  const intl = useIntl()
  const navigate = useNavigate()
  const {execute, loading} = usePostApi()
  const [showAddModal, setShowAddModal] = useState(false)
  const [inputVal, setInputVal] = useState('')

  const [galleryData, setGalleryData] = useState([])
  const fetchData = async () => {
    try {
      const url = `/media/get/all`
      const formData = {media_type: 'G'}
      const response = await execute(url, 'POST', formData)
      setGalleryData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [activeImageIndex, setActiveImageIndex] = useState(0)

  useEffect(() => {
    let interval
    if (hoveredIndex !== null) {
      interval = setInterval(() => {
        setActiveImageIndex((prevIndex) => {
          // const metaData = JSON.parse(each.metadata)

          const sliderImgs = JSON.parse(galleryData[hoveredIndex].metadata)
          const {media_ids} = sliderImgs
          return (prevIndex + 1) % media_ids.length
        })
      }, 1000) // Change image every 1 second
    } else {
      setActiveImageIndex(0) // Reset when not hovered
    }

    return () => clearInterval(interval)
  }, [hoveredIndex, galleryData])

  const filteredData = useMemo(() => {
    return galleryData
      ? galleryData.filter((each) => {
          const titleAr =
            each.media_title_ar &&
            each.media_title_ar.toLowerCase().includes(inputVal.toLowerCase())
          const titleEn =
            each.media_title_en &&
            each.media_title_en.toLowerCase().includes(inputVal.toLowerCase())
          const descAr =
            each.media_desc_ar && each.media_desc_ar.toLowerCase().includes(inputVal.toLowerCase())
          const descEn =
            each.media_desc_en && each.media_desc_en.toLowerCase().includes(inputVal.toLowerCase())

          return titleAr || titleEn || descAr || descEn
        })
      : []
  }, [inputVal, galleryData])

  const GalleryViewBC = [
    {
      title: `Media`,
      path: `/media`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `Gallery/Slider`,
      path: `/media`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Gallery/Slider`,
      path: `/media/gallery-slider`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const getCurrentUI = () => {
    if (loading) {
      return (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      )
    } else if (galleryData && galleryData.length > 0) {
      return (
        <>
          <PageTitle breadcrumbs={GalleryViewBC}>Videos</PageTitle>

          <div className='d-flex flex-wrap flex-stack mb-2'>
            <h3 className='fw-semibold default-cursor'>
              Attachments
              <span className='fs-6 text-gray-400 fw-normal ms-2'>{`Resources ${galleryData?.length}`}</span>
            </h3>

            <div className='d-flex my-2'>
              <button
                className={`d-none btn btn-icon btn-light-primary btn-sm`}
                // onClick={() => setView('table')}
                data-toggle='tooltip'
                title='Table View'
              >
                <KTIcon iconName='text-align-justify-center' className='fs-3' />
              </button>
              <button
                className={`d-none btn btn-icon btn-light-primary btn-sm ms-4`}
                // onClick={() => setView('grid')}
                data-toggle='tooltip'
                title='Grid View'
              >
                <KTIcon iconName='element-2' className='fs-3' />
              </button>
              <div className='d-flex align-items-center position-relative ms-4'>
                <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                <input
                  type='search'
                  id='kt_filter_search'
                  className='form-control form-control-white form-control-sm w-150px ps-9'
                  placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.SEARCH'})}
                  value={inputVal}
                  onChange={(e) => setInputVal(e.target.value)}
                />
              </div>
              <button
                className='btn btn-sm btn-light-primary ms-4'
                onClick={() => setShowAddModal(true)}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Upload
              </button>
            </div>
          </div>

          <>
            {filteredData.length === 0 ? (
              <div className='card h-250px d-flex justify-content-center align-items-center'>
                <h3 className='fw-semibold'>
                  <FormattedMessage
                    id='MSG.NO_DATA_MATCH'
                    values={{
                      inputVal: <span className='text-primary'>{inputVal}</span>,
                    }}
                  />
                </h3>
              </div>
            ) : (
              <div className='d-flex flex-wrap gap-3'>
                {filteredData?.map((each, index) => {
                  const metaData = JSON.parse(each.metadata)
                  const {media_ids} = metaData
                  return (
                    <div
                      className='image-con-width'
                      // style={{width: '190px', height: '180px'}}
                      onClick={() => {
                        navigate('/media/gallery-slider/details', {
                          state: {
                            title: each.media_title_en,
                            media_id: each.media_id,
                          },
                        })
                      }}
                    >
                      <div
                        key={each.media_id}
                        className='image-container image-con-height'
                        style={{position: 'relative'}}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                      >
                        <img
                          src={
                            hoveredIndex === index
                              ? media_ids?.[activeImageIndex]?.url
                              : media_ids[0]?.url
                          }
                          alt={each.media_title_en || each.media_title_ar}
                          className='w-100 h-100 rounded'
                        />

                        {/* Overlay with title */}
                        <div className='overlay'>
                          {/* <span className='image-title'>{each.title}</span> */}
                        </div>

                        {/* Indicator dots */}
                        {hoveredIndex === index && (
                          <div className='indicator-container'>
                            {media_ids.map((_, imgIndex) => (
                              <span
                                key={imgIndex}
                                className={`dot ${imgIndex === activeImageIndex ? 'active' : ''}`}
                              ></span>
                            ))}
                          </div>
                        )}
                      </div>
                      <span
                        className='d-inline-block text-truncate image-title'
                        style={{maxWidth: '100%'}}
                      >
                        {each.media_title_en || each.media_title_ar}
                      </span>
                    </div>
                  )
                })}
              </div>
            )}
          </>
        </>
      )
    } else {
      return (
        <div className='card h-250px d-flex flex-column justify-content-center align-items-center'>
          <h3 className='fw-semibold'>No data found.</h3>
          <button className='btn btn-light-primary btn-sm' onClick={() => setShowAddModal(true)}>
            <KTIcon iconName='plus' className='fs-3' />
            Upload
          </button>
        </div>
      )
    }
  }

  return (
    <>
      <UploadGallery
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        type='add'
        data={{}}
        loadData={fetchData}
      />

      {getCurrentUI()}
    </>
  )
}

export default GalleryMain
