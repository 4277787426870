import {useMemo, useState, useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import {useIntl, FormattedMessage} from 'react-intl'
import {Pagination} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import usePostApi from '../../../custom_hooks/usePostApi'
import {PageTitle} from '../../../../../_metronic/layout/core'
import UploadAudio from './UploadAudio'
import AudioFileIcon from './AudioFile.png'

const AudioViewBC = [
  {
    title: `Media`,
    path: `/media`,
    isSeparator: false,
    isActive: false,
  },
  {
    title: `Audio`,
    path: `/media`,
    isSeparator: true,
    isActive: false,
  },
  {
    title: `Audio`,
    path: `/media/audio`,
    isSeparator: false,
    isActive: false,
  },
]

const DataTable = ({columns, data, loading, fetchData}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const {state} = location
  const [view, setView] = useState('table')
  const [showAddModal, setShowAddModal] = useState(false)

  const [currentStatus, setCurrentStatus] = useState(1)

  useEffect(() => {
    if (state?.view) {
      setView(state?.view)
    }
  }, [state?.view])

  const [inputVal, setInputVal] = useState('')

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const status = each.status == 1 ? 'Active' : 'Inactive'
          const titleAr =
            each.media_title_ar &&
            each.media_title_ar.toLowerCase().includes(inputVal.toLowerCase())
          const titleEn =
            each.media_title_en &&
            each.media_title_en.toLowerCase().includes(inputVal.toLowerCase())

          const descAr =
            each.media_description_ar &&
            each.media_description_ar.toLowerCase().includes(inputVal.toLowerCase())
          const descEn =
            each.media_description_en &&
            each.media_description_en.toLowerCase().includes(inputVal.toLowerCase())
          const statusMatch = status && status.toLowerCase().includes(inputVal.toLowerCase())

          const currentStatusMatches = each.status == currentStatus

          return (titleAr || titleEn || descAr || descEn || statusMatch) && currentStatusMatches
        })
      : []
  }, [inputVal, data, currentStatus])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]
  const count = data?.length

  if (loading) {
    return (
      <div className='card h-250px'>
        <div className='m-auto d-flex flex-column align-items-center'>
          <div className='spinner-border spinner-primary mr-15'></div>
        </div>
      </div>
    )
  } else if (!data || !data.length) {
    return (
      <>
        <UploadAudio
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          type='add'
          data={{}}
          loadData={fetchData}
        />
        <div className='card h-250px d-flex flex-column justify-content-center align-items-center'>
          <h3 className='fw-semibold'>No data found.</h3>
          <button className='btn btn-sm btn-light-primary' onClick={() => setShowAddModal(true)}>
            <KTIcon iconName='plus' className='fs-3' />
            Upload
          </button>
        </div>
      </>
    )
  } else {
    return (
      <>
        <PageTitle breadcrumbs={AudioViewBC}>Audios</PageTitle>
        <UploadAudio
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          type='add'
          data={{}}
          loadData={fetchData}
        />

        {loading ? (
          <div className='card h-250px'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : (
          <>
            <div className='d-flex flex-wrap flex-stack mb-2'>
              <h3 className='fw-semibold default-cursor'>
                Attachments
                <span className='fs-6 text-gray-400 fw-normal ms-2'>{`Resources ${count}`}</span>
              </h3>
              {/* <div className='d-flex my-2 me-2'>
                <button
                  className={`btn btn-icon btn-${
                    view === 'table' ? 'primary' : 'light-primary'
                  } btn-sm`}
                  onClick={() => setView('table')}
                  data-toggle='tooltip'
                  title='Table View'
                >
                  <KTIcon iconName='text-align-justify-center' className='fs-3' />
                </button>
                <button
                  className={`btn btn-icon btn-${
                    view === 'grid' ? 'primary' : 'light-primary'
                  } btn-sm ms-4`}
                  onClick={() => setView('grid')}
                  data-toggle='tooltip'
                  title='Grid View'
                >
                  <KTIcon iconName='element-2' className='fs-3' />
                </button>
              </div> */}
            </div>
            <div
              className='card mb-0 mb-xl-8 overflow-auto'
              style={{minHeight: data?.length <= 1 ? '149px' : ''}}
            >
              <div className='card-header border-0 px-10'>
                <div className='card-title align-items-start flex-column'>
                  <ul className='nav'>
                    <li className='nav-item'>
                      <span
                        className={`nav-link btn btn-sm btn-color-muted ${
                          currentStatus === 1 ? 'active btn-active-light-success' : ''
                        } px-4 me-1 fs-6`}
                        onClick={() => setCurrentStatus(1)}
                      >
                        Active
                      </span>
                    </li>
                    <li className='nav-item'>
                      <span
                        className={`nav-link btn btn-sm btn-color-muted ${
                          currentStatus === 0 ? 'active btn-active-light-danger' : ''
                        } px-4 me-1 fs-6`}
                        onClick={() => setCurrentStatus(0)}
                      >
                        Inactive
                      </span>
                    </li>
                  </ul>
                </div>
                <div className='card-toolbar'>
                  <div className='d-flex align-items-center position-relative me-4 mb-1 mb-md-0'>
                    <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                    <input
                      type='search'
                      id='kt_filter_search'
                      className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                      placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.SEARCH'})}
                      value={inputVal}
                      onChange={(e) => setInputVal(e.target.value)}
                    />
                  </div>

                  <button
                    className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0'
                    onClick={() => setShowAddModal(true)}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    Upload
                  </button>
                  <button
                    className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0 ms-4'
                    onClick={() => fetchData(true)}
                    data-bs-toggle='tooltip'
                    title='Click to load the latest data.'
                  >
                    <i className='ki-solid ki-arrows-circle fs-2'></i>
                  </button>
                </div>
              </div>
              {view === 'table' ? (
                <div className='px-10'>
                  <table
                    className='table table-row-dashed table-row-gray-300 align-middle'
                    {...getTableProps()}
                  >
                    <thead>
                      <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                        <th className='min-w-200px text-start ps-3'>Title</th>
                        <th className='min-w-250px text-start'>Description</th>
                        <th className='min-w-100px text-start'>Author</th>
                        <th className='min-w-100px text-start'>Metadata</th>
                        <th className='min-w-100px text-start'>Status</th>
                        <th className='min-w-100px text-center'>
                          {intl.formatMessage({id: 'MENU.ACTIONS'})}
                        </th>
                      </tr>
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {page?.length > 0 ? (
                        page.map((row) => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td className='text-center' {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              ))}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td className='' colSpan={6}>
                            <div className='d-flex justify-content-center'>
                              <p className='text-dark default-cursor fs-6 mt-5'>
                                {inputVal === '' ? (
                                  intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                ) : (
                                  <FormattedMessage
                                    id='MSG.NO_DATA_MATCH'
                                    values={{
                                      inputVal: <span className='text-primary'>{inputVal}</span>,
                                    }}
                                  />
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className='d-flex flex-row flex-wrap gap-3 p-5'>
                  {filteredData?.length > 0 ? (
                    filteredData.map((item) => {
                      return (
                        <div key={item.media_id}>
                          <div className='card h-100'>
                            <img
                              src={AudioFileIcon}
                              alt={item.media_title_en}
                              className='h-150px w-150px cursor-pointer'
                              onClick={() =>
                                navigate('/media/audio/details', {
                                  state: {
                                    view: 'grid',
                                    media_id: item.media_id,
                                  },
                                })
                              }
                            />
                            <div className='card-body w-150px p-3'>
                              <h5 className='card-title fw-normal mb-0'>{item.media_title_ar}</h5>
                              {item.media_title_en && (
                                <p className='m-0'>({item.media_title_en})</p>
                              )}
                              <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                  <span
                                    className={`badge ${
                                      item.status === 1
                                        ? 'badge-light-success'
                                        : 'badge-light-danger'
                                    } fw-normal`}
                                  >
                                    {item.status === 1 ? 'Active' : 'Inactive'}
                                  </span>
                                </div>
                                <div>
                                  <button
                                    className='btn btn-unstyled p-0'
                                    onClick={() =>
                                      navigate('/media/audio/details', {
                                        state: {
                                          view: 'grid',
                                          media_id: item.media_id,
                                        },
                                      })
                                    }
                                  >
                                    <KTIcon iconName='pencil' className='fs-3' />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className='card w-100 h-200px d-flex justify-content-center align-items-center'>
                      <h3 className='fw-semibold fs-3'>No data found.</h3>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        {data?.length > 10 && (
          <Pagination>
            <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
            <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

            {[...Array(pageCount)].map((_, i) => (
              <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                {i + 1}
              </Pagination.Item>
            ))}

            <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
            <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />

            <div className='d-flex align-items-center mx-5'>
              <span className='me-2'>{intl.formatMessage({id: 'BTN.SHOW'})}</span>
              <select
                className='form-select form-select-solid fw-semibold w-75px me-2'
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {pageOptions.map((option) => (
                  <option key={option} value={option} className='select-bg'>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </Pagination>
        )}
      </>
    )
  }
}

const AudioTable = () => {
  const navigate = useNavigate()
  const {execute, loading} = usePostApi()
  const [audiosData, setAudiosData] = useState([])
  const fetchData = async () => {
    try {
      const url = `/media/get/all`
      const formData = {media_type: 'A'}
      const response = await execute(url, 'POST', formData)
      setAudiosData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const audioRefs = useRef([]) // Store references to audio elements
  let currentAudio = null // To keep track of the currently playing audio

  const handlePlay = (index) => {
    if (currentAudio && currentAudio !== audioRefs.current[index]) {
      currentAudio.pause()
      currentAudio.currentTime = 0 // Reset the audio time to the beginning
    }

    // Set the new audio as the current audio
    currentAudio = audioRefs.current[index]
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'img',
        Cell: ({row}) => {
          return (
            <div
              className='min-w-170px text-start ps-3 cursor-pointer'
              onClick={() =>
                navigate('/media/audio/details', {
                  state: {
                    view: 'table',
                    media_id: row.original.media_id,
                  },
                })
              }
            >
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-45px me-5'>
                  <img src={AudioFileIcon} alt='' />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <p className='text-dark fw-semibold text-hover-primary default-cursor fs-6 m-0'>
                    {row.original.media_title_ar}
                    <br />
                    {row.original?.media_title_en && `(${row.original.media_title_en})`}
                  </p>
                </div>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'IssuedBy',
        accessor: 'issuedBy',
        Cell: ({row}) => {
          return (
            <p
              className={`text-dark text-hover-primary default-cursor d-block fs-6 text-start cursor-pointer`}
              onClick={() =>
                navigate('/media/audio/details', {
                  state: {
                    view: 'table',
                    media_id: row.original.media_id,
                  },
                })
              }
            >
              {row.original?.media_desc_ar}
              <br />
              {row.original?.media_desc_en && `(${row.original.media_desc_en})`}
            </p>
          )
        },
      },
      {
        Header: 'Author',
        accessor: 'author',
        Cell: ({row}) => {
          const metaData = JSON.parse(row.original?.metadata)
          const metaInfo = JSON.parse(metaData?.meta_info)
          return (
            <p
              className={`text-dark text-hover-primary default-cursor d-block fs-6 text-start cursor-pointer`}
              onClick={() =>
                navigate('/media/audio/details', {
                  state: {
                    view: 'table',
                    media_id: row.original.media_id,
                  },
                })
              }
            >
              {metaInfo?.author}
            </p>
          )
        },
      },
      {
        Header: 'MetaData',
        accessor: 'metaData',
        Cell: ({row}) => {
          const metaData = JSON.parse(row.original.metadata)

          function bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return 'n/a'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
          }
          return (
            <p className={`text-dark text-hover-primary default-cursor d-block fs-6 text-start`}>
              {metaData?.extension}
              <br />
              {bytesToSize(metaData?.size)}
            </p>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p
                className={`fw-normal default-cursor badge badge-light-${
                  row.original.status === 1 ? 'success' : 'danger'
                }`}
              >
                {row.original?.status === 1 ? 'Active' : 'Inactive'}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({row, rowIndex}) => (
          <div className='d-flex justify-content-between align-items-center'>
            <audio
              ref={(el) => {
                if (el && !audioRefs.current.includes(el)) {
                  // Assign ref only if it isn't already added
                  audioRefs.current[rowIndex] = el
                }
              }}
              src={row.original.media_location_url}
              controls
              onPlay={() => handlePlay(rowIndex)} // Handle play event
            />
          </div>
        ),
      },
    ],
    []
  )

  return <DataTable columns={columns} data={audiosData} loading={loading} fetchData={fetchData} />
}

export default AudioTable
