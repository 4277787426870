import {useState, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {KTIcon} from '../../../../../_metronic/helpers'
import usePostApi from '../../../custom_hooks/usePostApi'
import UploadVideo from './UploadVideo'

function VideoDetails() {
  const location = useLocation()
  const navigate = useNavigate()
  const {execute, loading} = usePostApi()
  const {state}: any = location
  const [showEditModal, setShowEditModal] = useState(false)

  const [videoData, setVideoData]: any = useState({})
  const [metaData, setMetaData]: any = useState({})
  const [metaInfo, setMetaInfo]: any = useState({})

  const fetchData = async () => {
    try {
      const url = `/media/get`
      const formData = {media_id: state?.media_id}
      const response = await execute(url, 'POST', formData)
      setVideoData(response?.data[0])
      const parsedMetaData = JSON.parse(response.data[0].metadata)
      setMetaData(parsedMetaData)
      setMetaInfo(JSON.parse(parsedMetaData?.meta_info))
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  function bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

    if (bytes === 0) return 'n/a'

    const i = Math.floor(Math.log(bytes) / Math.log(1024))

    if (i === 0) return `${bytes} ${sizes[i]}`

    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
  }

  const AudioViewBC = [
    {
      title: `Media`,
      path: `/media`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `Video`,
      path: `/media/video`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Video`,
      path: `/media/video`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `Video`,
      path: `/media/video`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `${videoData.media_title_en || videoData.media_desc_ar}`,
      path: `/media/video/details`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const getVideoType = (url: string) => {
    if (!url) return
    // Check if the URL is a YouTube link (both short and long formats)
    if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
      const videoId = url?.match(
        /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.*v=))([\w-]{11})/
      )
      return videoId ? {type: 'youtube', id: videoId[1]} : null
    }

    // Check if the URL is an mp4 link
    if (url?.endsWith('.mp4')) {
      return {type: 'mp4', url}
    }

    return null
  }

  let video = getVideoType(videoData?.media_location_url)
  useEffect(() => {
    if (videoData?.media_location_url) {
      video = getVideoType(videoData?.media_location_url)
    }
  }, [videoData])

  return (
    <>
      <UploadVideo
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        type='edit'
        data={videoData}
        loadData={fetchData}
      />
      <PageTitle breadcrumbs={AudioViewBC}>Attachment Details</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h3 className='fw-semibold fs-3 mb-5'>File Information</h3>
        <button
          className='btn btn-light-primary btn-sm'
          onClick={() => navigate('/media/video', {state: {view: state?.view}})}
        >
          <KTIcon iconName='arrow-left' className='fs-2' />
          Back
        </button>
      </div>
      {loading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <div className='d-flex flex-column flex-md-row'>
          {video ? (
            video.type === 'youtube' ? (
              // YouTube video
              <iframe
                // width='660'
                // height='415'
                className='w-100'
                src={`https://www.youtube.com/embed/${video.id}`}
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            ) : (
              // MP4 video
              <video className='w-100' height='415' controls>
                <source src={video.url} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            )
          ) : (
            <p>Invalid video URL</p>
          )}

          <div className='w-100 w-md-50 ms-md-5 mt-5 mt-md-0'>
            <div className='d-flex align-items-center mb-3'>
              <h3 className='fw-semibold fs-3 me-4'>
                {videoData.media_title_en || videoData.media_title_ar}
              </h3>
              <button
                className='btn btn-light-primary btn-sm'
                onClick={() => setShowEditModal(true)}
              >
                <KTIcon iconName='pencil' className='fs-3' />
                Edit
              </button>
            </div>

            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Title (Arabic)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{videoData?.media_title_ar || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Title (English)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{videoData?.media_title_en || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Description (Arabic)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{videoData?.media_desc_ar || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Description (English)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{videoData?.media_desc_en || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Status</label>
              <div className='col-lg-8'>
                <span
                  className={`fw-normal default-cursor badge badge-light-${
                    videoData.status === 1 ? 'success' : 'danger'
                  }`}
                >
                  {videoData?.status === 1 ? 'Active' : 'Inactive'}
                </span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Extension</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{metaData?.extension || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Mime</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{metaData?.mime || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>File size</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>
                  {metaData?.size ? bytesToSize(metaData?.size) : '-'}
                </span>
              </div>
            </div>

            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Tags</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{metaInfo?.tags?.join(', ') || '-'}</span>
              </div>
            </div>

            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Uploaded on</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{videoData?.created_ts || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Updated on</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{videoData?.updated_ts || '-'}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default VideoDetails
