import {ViewArticleDrawer} from './ViewArticleDrawer'
const SideDrawer = (props) => {
  const {value} = props
  const sideDrawerMethod = () => {
    if (value && value.type) {
      switch (value.type) {
        case 'view_article':
          return <ViewArticleDrawer props={value} />

        default:
          return <ViewArticleDrawer props={value} />
      }
    }
    return null
  }
  return (
    <div
      id='side_activities'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='side-activities'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#side_activities_toggle'
      data-kt-drawer-close='#side_activities_close'
    >
      {sideDrawerMethod()}
    </div>
  )
}
export {SideDrawer}
