import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core'
import {usePageData} from '../../../core/PageData'
import {useParams} from 'react-router-dom'
// import {FindBrandnameByCode} from '../../../../../app/modules/FindPodIdByPodCode'

const PageTitle = () => {
  // const brandName = FindBrandnameByCode()
  const {id} = useParams()
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, classes} = useLayout()
  const appPageTitleDirection = config.app?.pageTitle?.direction

  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'
    >
      <h1 className='page-heading d-flex text-dark fw-semibold fs-3 my-0 flex-column justify-content-center'>
        {pageTitle}
      </h1>

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.app?.pageTitle &&
        config.app?.pageTitle?.breadCrumb && (
          <>
            {config.app?.pageTitle?.direction === 'row' && (
              <span className='h-20px border-gray-300 border-start mx-4'></span>
            )}
            <ul className='breadcrumb breadcrumb-separatorless fs-7 my-0'>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                    <Link
                      className={`text-${
                        pageBreadcrumbs.length > 1 && pageBreadcrumbs.length - 1 === index
                          ? 'dark'
                          : 'muted text-hover-primary'
                      }`}
                      to={item.path}
                    >
                      {index === 0 ? `${item.title}` : item.title}
                    </Link>
                  ) : (
                    <span className='bullet bg-gray-400 w-5px h-2px'></span>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
    </div>
  )
}

export {PageTitle}
