import CryptoJS from 'crypto-js'

const SECRET_KEY = process.env.REACT_APP_LS_SECRET_KEY

const SecureLocalStorage = {
  setItem: (key, value) => {
    try {
      const stringValue = JSON.stringify(value)
      const encryptedValue = CryptoJS.AES.encrypt(stringValue, SECRET_KEY).toString()
      localStorage.setItem(key, encryptedValue)
    } catch (error) {
      console.error('Error encrypting value:', error)
      // Handle the error appropriately, e.g., log or display a user-friendly message
    }
  },

  getItem: (key) => {
    try {
      const encryptedValue = localStorage.getItem(key)
      if (!encryptedValue) return null
      const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY)
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8)
      return JSON.parse(decryptedValue)
    } catch (error) {
      console.error('Error decrypting value:', error)
      // Handle the error appropriately, e.g., log or display a user-friendly message
      return null // Return null in case of decryption failure
    }
  },

  removeItem: (key) => {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      console.error('Error removing item:', error)
      // Handle the error appropriately
    }
  },
}

export default SecureLocalStorage
