import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import usePostApi from '../../custom_hooks/usePostApi'
import {UseToast} from '../../useToast'
import {useThemeMode} from '../../../../_metronic/partials'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {format} from 'date-fns'
import Select from 'react-select'
import AddNewAttachment from '../../media/components/image/AddNewAttachment'

function AddNewRule({showModal, setShowModal, type = 'add', data, loadData}: any) {
  const {mode} = useThemeMode()
  const lang = useLang()
  const {execute, postLoading} = usePostApi()
  const [showAddMediaModal, setShowAddMediaModal] = useState(false)
  const [singleRulerPhoto, setSingleRulerPhoto] = useState('')
  const [singleMediaId, setSingleMediaId] = useState('')
  const [singleTitleAr, setSingleTitleAr] = useState('')
  const [singleTitleEn, setSingleTitleEn] = useState('')

  const [mediaOptions, setMediaOptions] = useState([])
  const mediafetchData = async () => {
    try {
      const url = `/media/active/get`
      const formData = {media_type: 'I'}
      const response = await execute(url, 'POST', formData)
      const options: any = (response.data || []).map((each: any) => ({
        value: each.media_location_url,
        media_id: each.media_id,
        label: `${each.media_title_ar} (${each.media_title_en})`,
        label_ar: each.media_title_ar,
        label_en: each.media_title_en,
      }))
      if (options) {
        setMediaOptions(options)
        if (options.length === 1) {
          setSingleRulerPhoto(options[0].value)
          setSingleMediaId(options[0].media_id)
          setSingleTitleAr(options[0].label_ar)
          setSingleTitleEn(options[0].label_en)
        }
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    mediafetchData()
  }, [])

  const step1Schema = Yup.object().shape({
    ruler_title_ar: Yup.string().required("Ruler's title in Arabic is required"),
    ruler_msg_ar: Yup.string().required("Ruler's message in Arabic is required"),
    ruler_photo: Yup.string().required("Ruler's photo is required"),
    start_date: Yup.string().required('Rule start date is required'),
    end_date: Yup.string().when('start_date', (start_date: any, schema) => {
      return schema
        .test('end_date', 'Expiry date can not be negative', function (value) {
          return !start_date || !value || new Date(value) >= new Date(start_date)
        })
        .required('Rule end date is required')
    }),
  })

  const [initialValues, setInitialVals] = useState({
    ruler_title_ar: '',
    ruler_title_en: '',
    ruler_msg_ar: '',
    ruler_msg_en: '',
    ruler_photo: singleRulerPhoto || '',
    start_date: '',
    end_date: '',
    media_id: singleMediaId || '',
    media_title_ar: singleTitleAr || '',
    media_title_en: singleTitleEn || '',
  })

  useEffect(() => {
    if (type === 'edit') {
      const dataObj = {
        ruler_title_ar: data?.ruler_title_ar,
        ruler_title_en: data?.ruler_title_en || '',
        ruler_msg_ar: data?.ruler_message_ar,
        ruler_msg_en: data?.ruler_message_en || '',
        ruler_photo: data?.ruler_photo,
        start_date: data?.start_dt,
        end_date: data?.end_dt,
        media_id: data.media_id,
        media_title_ar: data?.media_title_ar,
        media_title_en: data?.media_title_en || '',
      }
      setInitialVals(dataObj)
    }
  }, [type, data])

  const onSubmit = async (values: any, {resetForm}: {resetForm: () => void}) => {
    try {
      const url = type === 'add' ? '/ruler/create' : '/ruler/update'
      let formData = {
        ruler_id: data?.ruler_id,
        end_dt: values.end_date,
        media_id: values.media_id,
        start_dt: values.start_date,
        ruler_photo: values.ruler_photo,
        media_title_ar: values.media_title_ar,
        media_title_en: values.media_title_en,
        ruler_title_ar: values.ruler_title_ar,
        ruler_title_en: values.ruler_title_en,
        ruler_message_ar: values.ruler_msg_ar,
        ruler_message_en: values.ruler_msg_en,
      }

      const response = await execute(url, 'POST', formData, 'post')
      loadData()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: true,
        },
        'success'
      )
      resetForm()
      setShowModal(false)
    } catch (error: any) {
      console.error('Error uploading document:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: true,
        },
        'error'
      )
      throw error // Re-throw the error to handle it in the calling function
    }
  }

  const handleMediaChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('ruler_photo', selectedOption.value || '')
      setFieldValue('media_id', selectedOption.media_id || '')
      setFieldValue('media_title_ar', selectedOption.label_ar || '')
      setFieldValue('media_title_en', selectedOption.label_en || '')
    } else {
      setFieldValue('ruler_photo', '')
      setFieldValue('media_id', '')
      setFieldValue('media_title_ar', '')
      setFieldValue('media_title_ar', '')
    }
  }

  const CustomOption = (props: any) => {
    const {data, innerRef, innerProps} = props
    return (
      <div ref={innerRef} {...innerProps} className='d-flex flex-row m-2'>
        <div className='d-flex'>
          <div className='symbol me-2'>
            <img src={data.value} alt='Brand Logo' style={{width: '35px', height: '35px'}} />
          </div>
        </div>
        <div className='ms-4 d-flex'>
          <div className='row'>
            <p className='text-dark text-hover-primary fs-8 mb-0'>{data.label_ar}</p>
            <p className='text-dark text-hover-primary fs-8 mt-0'>
              {data.label_en ? `(${data.label_en})` : ' '}
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <AddNewAttachment
        showModal={showAddMediaModal}
        setShowModal={setShowAddMediaModal}
        type='add'
        data={{}}
        loadData={mediafetchData}
      />
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        dialogClassName='custom-modal modal-dialog-centered'
        contentClassName='custom-modal-content'
      >
        <Modal.Body className='overflow-auto w-100'>
          <div className='card py-0 overflow-auto'>
            <div className='w-100 m-auto'>
              <div className='d-flex justify-content-between mb-10'>
                <h3 className='fs-3 fw-semibold'>Add New Rule</h3>
                <button
                  type='button'
                  className='d-block btn btn-sm btn-icon btn-light'
                  onClick={() => setShowModal(false)}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </button>
              </div>

              <Formik
                validationSchema={step1Schema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({setFieldValue, values}: any) => {
                  return (
                    <Form className='w-100'>
                      <div className='d-flex flex-column flex-md-row justify-content-between'>
                        <div className='w-100 w-md-50 me-md-2'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Ruler's Title (Arabic)</span>
                            <i
                              className='fas fa-info-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Please enter the attachment title in Arabic.'
                            ></i>
                          </label>
                          <Field
                            id='ruler_title_ar'
                            type='text'
                            name='ruler_title_ar'
                            className='form-control form-control-solid'
                            placeholder='أدخل عنوان المسطرة'
                            dir='rtl'
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='ruler_title_ar' />
                          </div>
                        </div>
                        <div className='w-100 w-md-50 ms-md-2 mt-3 mt-md-0'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            <span>Ruler's Title (English)</span>
                            <i
                              className='fas fa-info-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Please enter the attachment title in English.'
                            ></i>
                          </label>
                          <Field
                            id='ruler_title_en'
                            type='text'
                            name='ruler_title_en'
                            className='form-control form-control-solid'
                            placeholder="Enter the ruler's title"
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='ruler_title_en' />
                          </div>
                        </div>
                      </div>

                      <div className='d-flex flex-column flex-md-row justify-content-between mt-3'>
                        <div className='w-100 w-md-50 me-md-2'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Ruler's Message (Arabic)</span>
                            <i
                              className='fas fa-info-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Please enter the attachment title in Arabic.'
                            ></i>
                          </label>
                          <Field
                            as='textarea'
                            id='ruler_msg_ar'
                            type='text'
                            name='ruler_msg_ar'
                            className='form-control form-control-solid'
                            placeholder='أدخل رسالة المسطرة'
                            dir='rtl'
                            rows={3}
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='ruler_msg_ar' />
                          </div>
                        </div>
                        <div className='w-100 w-md-50 ms-md-2 mt-3 mt-md-0'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            <span>Ruler's Message (English)</span>
                            <i
                              className='fas fa-info-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Please enter the attachment title in English.'
                            ></i>
                          </label>
                          <Field
                            as='textarea'
                            id='ruler_msg_en'
                            type='text'
                            name='ruler_msg_en'
                            className='form-control form-control-solid'
                            placeholder="Enter the ruler's message"
                            rows={3}
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='ruler_msg_en' />
                          </div>
                        </div>
                      </div>

                      <div className='d-flex flex-column flex-md-row justify-content-between mt-3'>
                        <div className='w-100 w-md-50 me-md-2'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Rule Start Date</span>
                            <i
                              className='fas fa-info-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Please enter the attachment title in Arabic.'
                            ></i>
                          </label>
                          <Field name='start_date'>
                            {({form, field}: any) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date: any) => {
                                if (date instanceof Date && !isNaN(date.getTime())) {
                                  const dateTime = format(date, 'yyyy-MM-dd HH:mm:ss')
                                  setFieldValue('start_date', dateTime)
                                } else {
                                  setFieldValue('start_date', null)
                                }
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd-MM-yyyy HH:mm:ss'
                                    minDate={new Date()}
                                    showTimeInput
                                    onChange={(date: any) => {
                                      handleChange(date)
                                    }}
                                    placeholderText='Select date'
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    locale={lang || 'en'}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage name='start_date' component='div' className='text-danger' />
                        </div>
                        <div className='w-100 w-md-50 ms-md-2 mt-3 mt-md-0'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            <span>Rule End Date</span>
                            <i
                              className='fas fa-info-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Please enter the attachment title in English.'
                            ></i>
                          </label>
                          <Field name='end_date'>
                            {({form, field}: any) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date: any) => {
                                if (date instanceof Date && !isNaN(date.getTime())) {
                                  if (
                                    date.getHours() === 0 &&
                                    date.getMinutes() === 0 &&
                                    date.getSeconds() === 0
                                  ) {
                                    date.setHours(23)
                                    date.setMinutes(59)
                                    date.setSeconds(59)
                                  }
                                  const dateTime = format(date, 'yyyy-MM-dd HH:mm:ss')
                                  setFieldValue('end_date', dateTime)
                                } else {
                                  setFieldValue('end_date', null)
                                }
                              }

                              const tomorrow = new Date()
                              tomorrow.setDate(tomorrow.getDate() + 1)

                              const timeFormatFunc = (val: any) => {
                                if (val) {
                                  const dateValue = new Date(val)
                                  if (
                                    dateValue.getHours() === 0 &&
                                    dateValue.getMinutes() === 0 &&
                                    dateValue.getSeconds() === 0
                                  ) {
                                    const newValue = new Date(val)
                                    newValue.setHours(23)
                                    newValue.setMinutes(59)
                                    return newValue
                                  } else {
                                    return val
                                  }
                                } else {
                                  return ''
                                }
                              }

                              let minDate = null

                              if (values && values.start_date) {
                                const ruleStartDate = new Date(values.start_date)
                                minDate = ruleStartDate.setDate(ruleStartDate.getDate() + 1)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={timeFormatFunc(value)}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd-MM-yyyy HH:mm:ss'
                                    // minDate={new Date(values.start_date) || tomorrow}
                                    minDate={minDate || tomorrow}
                                    showTimeInput
                                    onChange={(date: any) => {
                                      handleChange(date)
                                    }}
                                    placeholderText='Select date'
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    locale={lang || 'en'}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage name='end_date' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='d-flex flex-column flex-md-row justify-content-between mt-3'>
                        <div className='w-100 w-md-50 me-md-2'>
                          <label className='fs-6 fw-semibold form-label mb-2 required'>
                            Ruler's Photo
                          </label>
                          <div className='d-flex align-items-center'>
                            <div style={{width: '95%'}}>
                              <Select
                                value={mediaOptions.find(
                                  (option: any) => option.value === values.ruler_photo
                                )}
                                styles={{
                                  control: (baseStyles: any) => ({
                                    ...baseStyles,
                                    backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                    color: '#ffffff',
                                    borderWidth: '0px',
                                  }),
                                  menu: (baseStyles: any) => ({
                                    ...baseStyles,
                                    backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                                  }),
                                  singleValue: (baseStyles: any, state: any) => ({
                                    ...baseStyles,
                                    color: mode === 'dark' ? '#92929f' : '#000000',
                                  }),
                                  input: (baseStyles: any) => ({
                                    ...baseStyles,
                                    color: mode === 'dark' ? '#92929f' : '#000000',
                                  }),
                                  menuList: (baseStyles: any) => ({
                                    ...baseStyles,
                                    maxHeight: '170px', // Set max height for dropdown options
                                    overflowY: 'auto', // Allow scrolling when maxHeight is reached
                                  }),
                                  option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                                    ...baseStyles,
                                    backgroundColor: isSelected
                                      ? '#C2C2C2'
                                      : isFocused
                                      ? '#e0e0e0'
                                      : baseStyles.backgroundColor,
                                    color: isSelected
                                      ? '#000000'
                                      : isFocused
                                      ? '#000000'
                                      : baseStyles.color,
                                    minHeight: '40px', // Minimum height for options
                                    display: 'flex',
                                    alignItems: 'center', // Center content vertically
                                  }),
                                  menuPortal: (baseStyles: any) => ({
                                    ...baseStyles,
                                    zIndex: 9999, // Ensure menuPortal renders above the modal
                                  }),
                                }}
                                theme={(theme: any) => ({
                                  ...theme,
                                  borderRadius: 5,
                                  colors: {
                                    ...theme.colors,
                                    primary25: 'rgba(80, 107, 236, 0.5)',
                                    primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                  },
                                })}
                                className='react-select-container flex-fill'
                                classNamePrefix='react-select'
                                options={mediaOptions}
                                isSearchable
                                placeholder='Select Media'
                                onChange={(val: any) => handleMediaChange(setFieldValue, val)}
                                components={{
                                  Option: CustomOption, // Custom option component
                                }}
                                menuPortalTarget={document.body} // Attach the menu to the body to escape modal's z-index issues
                              />
                            </div>
                            <button
                              className='btn btn-icon btn-light-primary btn-sm ms-2'
                              type='button'
                              onClick={() => setShowAddMediaModal(true)}
                              data-bs-toggle='tooltip'
                              title='Add new media.'
                            >
                              <KTIcon iconName='plus' className='fs-2' />
                            </button>
                          </div>
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='ruler_photo'
                          />
                        </div>
                        <div className='w-100 w-md-50 ms-md-2 mt-3 ms-md-0'></div>
                      </div>

                      <div className='w-100 d-flex justify-content-end mt-5'>
                        <div>
                          <button
                            type='button'
                            className='btn btn-sm btn-light me-2'
                            onClick={() => setShowModal(false)}
                          >
                            <KTIcon iconName='cross' className='fs-3' />
                            Close
                          </button>
                          <button
                            type='submit'
                            className='btn btn-sm btn-light-primary ms-2'
                            disabled={postLoading}
                          >
                            {postLoading ? (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            ) : (
                              <>
                                <KTIcon iconName='check' className='fs-3' />
                                Submit
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddNewRule
