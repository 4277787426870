import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import usePostApi from '../../modules/custom_hooks/usePostApi'
import {KTIcon} from '../../../_metronic/helpers'
import {UseToast} from '../../modules/custom_hooks/useToast'
import {useThemeMode} from '../../../_metronic/partials'
import {Modal} from 'react-bootstrap'
const EditSubCategory = (props: any) => {
  const {
    category_id,
    media_id,
    sub_category_name_ar,
    sub_category_name_en,
    sub_category_id,
    sub_category_desc_ar,
    sub_category_desc_en,
    status,
  } = props.id || {}
  const {execute, postLoading} = usePostApi()
  const {mode} = useThemeMode()
  const validationSchema = Yup.object().shape({
    sub_category_name_ar: Yup.string().required('Sub Category Name is required'),
    sub_category_desc_ar: Yup.string().required('Sub Category description is required'),
  })

  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `/subcategory/update`
    try {
      const requestData = {
        category_id: category_id,
        media_id: media_id,
        sub_category_id: sub_category_id,
        sub_category_desc_ar: values.sub_category_desc_ar,
        sub_category_desc_en: values.sub_category_desc_en,
        sub_category_name_ar: values.sub_category_name_ar,
        sub_category_name_en: values.sub_category_name_en,
        status: values.status ? 1 : 0,
      }
      const response = await execute(url, 'POST', requestData, 'post')
      resetForm()
      props.subCategoryFetchData()
      props.setShowModal(false)
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <Modal
        show={props.showModal}
        onHide={() => props.setShowModal(false)}
        centered
        dialogClassName='custom-modal modal-dialog-centered'
        contentClassName='custom-modal-content'
        style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
      >
        <Modal.Body className='overflow-auto'>
          <div className='card py-0 overflow-auto'>
            <div className='w-100 m-auto'>
              <div className='d-flex justify-content-between mb-6'>
                <h4 className='fw-semibold'>Edit Sub Category</h4>
                <button
                  type='button'
                  className='d-block btn btn-sm btn-icon btn-light'
                  onClick={() => {
                    props.setShowModal(false)
                  }}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </button>
              </div>
              <Formik
                initialValues={{
                  sub_category_name_ar: '' || sub_category_name_ar,
                  sub_category_name_en: '' || sub_category_name_en,
                  sub_category_desc_ar: '' || sub_category_desc_ar,
                  sub_category_desc_en: '' || sub_category_desc_en,
                  status: '' || status === 1,
                }}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({values, resetForm}) => {
                  return (
                    <Form>
                      <div className='d-flex flex-column flex-md-row justify-content-between'>
                        <div className='w-100 w-md-50 me-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                          >
                            Sub Category Name (Arabic)
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='floatingInput'
                            placeholder='Enter Sub Category Name'
                            name='sub_category_name_ar'
                            dir='rtl'
                          />
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='sub_category_name_ar'
                          />
                        </div>
                        <div className='w-100 w-md-50 ms-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                          >
                            Sub Category Name (English)
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='floatingInput'
                            placeholder='Enter Sub Category Name'
                            name='sub_category_name_en'
                          />
                        </div>
                      </div>
                      <div className='d-flex flex-column flex-md-row justify-content-between'>
                        <div className='w-100 w-md-50 me-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                          >
                            Sub Category Description (Arabic)
                          </label>
                          <Field
                            id='brand_des_arabic'
                            as='textarea'
                            className={`form-control form-control form-control-solid`}
                            name='sub_category_desc_ar'
                            placeholder='Briefly describe your Sub Category'
                            rows={3}
                            dir='rtl'
                          />
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='sub_category_desc_ar'
                          />
                        </div>
                        <div className='w-100 w-md-50 ms-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                          >
                            Sub Category Description (English)
                          </label>
                          <Field
                            id='brand_des_english'
                            as='textarea'
                            className={`form-control form-control form-control-solid`}
                            name='sub_category_desc_en'
                            placeholder='Briefly describe your Sub Category'
                            rows={3}
                          />
                        </div>
                      </div>
                      <div className='w-100 w-md-50 me-md-2 mb-2'>
                        <div className='form-floating flex-fill'>
                          <div className='form-check form-check-solid form-switch mb-0'>
                            <Field
                              className='form-check-input w-40px h-20px'
                              type='checkbox'
                              name='status'
                              id='toggle'
                              style={{
                                backgroundColor: values.status
                                  ? '#009688'
                                  : mode === 'dark'
                                  ? '#1b1b29'
                                  : '#f4f4f4',
                              }}
                            />
                            <span
                              className={`me-4 fw-semibold ${
                                values.status ? 'text-success' : 'text-danger'
                              }`}
                            >
                              {values.status ? 'Active' : 'Inactive'}
                            </span>
                            <ErrorMessage component='div' className='text-danger' name='status' />
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <button
                          type='reset'
                          onClick={() => {
                            handleCancel(resetForm)
                            props.setShowModal(false)
                          }}
                          className='btn btn-sm btn-light me-2'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-sm btn-light-primary'
                          data-kt-menu-dismiss='true'
                        >
                          {postLoading ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            <>
                              <KTIcon iconName='check' className='fs-7' />
                              Save
                            </>
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export {EditSubCategory}
