import {useState} from 'react'
import axios, {AxiosRequestConfig} from 'axios'
import sha512 from 'crypto-js/sha512'
import SecureLocalStorage from './SecureLocalStorage'
import Swal from 'sweetalert2'
import {useAuth} from '../auth'

const usePostApi = () => {
  const {logout} = useAuth()
  const [loading, setLoading] = useState(true)
  const [postLoading, setPostLoading] = useState(false)
  const [error, setError] = useState<null | any>(null)
  const [response, setResponse] = useState<null | any>(null)

  const execute = async (
    url: string | null,
    method: AxiosRequestConfig['method'],
    data: any,
    type = 'get'
  ) => {
    if (!url) {
      setLoading(false)
      return
    }
    if (type === 'post') {
      setPostLoading(true)
    } else {
      setLoading(true)
    }
    setError(null)
    setResponse(null)

    const userInfo = SecureLocalStorage.getItem('user_info')

    const endpoint = process.env.REACT_APP_API_BASE_URL + url
    const endpoint_checksum = sha512(endpoint).toString()

    // Calculate timestamp
    const timestamp = Math.floor(Date.now() / 1000)

    // Calculate x-api-magic-key
    const user_id = userInfo?.user_id
    const auth_token = userInfo?.auth_token
    const base64ApiKeyPass = btoa(
      `${process.env.REACT_APP_API_KEY}:${process.env.REACT_APP_API_PASS}`
    )
    const concatenatedString =
      JSON.stringify(data) +
      timestamp +
      auth_token +
      base64ApiKeyPass +
      '-' +
      `${process.env.REACT_APP_API_DATA_CHECKSUM_FILLER}`
    const calculatedMagicKey = sha512(concatenatedString).toString()

    const headers: {[key: string]: string | number} = {
      'x-api-magic-key': calculatedMagicKey,
      'x-api-timestamp': timestamp,
      'x-api-endpoint': url,
      'x-api-endpoint-checksum': endpoint_checksum,
      'x-api-key': process.env.REACT_APP_API_KEY || '',
      'x-api-pass': process.env.REACT_APP_API_PASS || '',
    }

    if (auth_token) {
      headers['x-api-token'] = auth_token
    }

    if (user_id) {
      headers['x-api-user-id'] = user_id
    }

    try {
      const config: AxiosRequestConfig = {
        method,
        url: endpoint,
        data,
        headers,
      }

      const result = await axios(config)
      setLoading(false)
      setPostLoading(false)
      setResponse(result.data)
      return result.data
    } catch (err: any) {
      setLoading(false)
      setPostLoading(false)
      setError(err)
      setResponse(err.response ? err.response.data : err.message)
      if (err.response.data.status_code === 'SC0003') {
        Swal.fire({
          title: 'Your session has expired. Please log in again.',
          confirmButtonText: 'Login',
          allowOutsideClick: false, // Disable closing the alert by clicking outside
          allowEscapeKey: false,

          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `,
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `,
          },
          customClass: {
            confirmButton: 'btn btn-primary btn-sm',
          },
        }).then((result: any) => {
          if (result.isConfirmed) {
            logout()
          }
        })
      }
      throw err
    }
  }

  return {loading, error, execute, response, postLoading}
}

export default usePostApi
