import {useEffect, useMemo, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import usePageTitle from '../custom_hooks/usePageTitle'
import {Pagination} from 'react-bootstrap'
import CreateNewArticleModal from './add_article/CreateNewArticleModal'
import Articlesknowmore from './Articlesknowmore'
import {KTIcon} from '../../../_metronic/helpers'
// import {UseToast} from '../../useToast'
import {useIntl, FormattedMessage} from 'react-intl'
import usePostApi from '../custom_hooks/usePostApi'
import EditArticleModal from './edit_article/EditArticleModal'
import {SideDrawer} from './view_article/SideDrawer'
import ArticleContext from './ArticleContext'
const DataTable = ({columns, data, loading, fetchData, refresh}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  const [showKM, setShowKM] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentStatus, setCurrentStatus] = useState(1)
  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const TitleMatches =
            each.title_en && each.title_en.toLowerCase().includes(inputVal.toLowerCase())
          const currentStatusMatches = each.status === currentStatus
          return TitleMatches && currentStatusMatches
        })
      : []
  }, [inputVal, currentStatus, data])

  const TableInstance = useTable({columns, data: filteredData}, usePagination)

  const activePage = TableInstance.page
  const activePageCount = TableInstance.pageCount
  const activePageIndex = TableInstance.state.pageIndex
  const activePageSize = TableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    TableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    TableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset

  const pageOptions = [5, 10, 20, 30, 40, 50]

  return (
    <ArticleContext.Consumer>
      {(value) => {
        const {id, updateId} = value

        return (
          <>
            <>
              {loading && refresh === false ? (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : data.length > 0 ? (
                <>
                  <div className='d-flex flex-wrap flex-stack '>
                    <div className='d-flex'>
                      <h1 className='fw-semibold fs-3'>Manage Articles</h1>
                    </div>
                    <div className='d-flex'>
                      <div className='d-flex'>
                        <div className='m-auto d-flex flex-column align-items-center'>
                          <button className='btn btn-sm btn-light' onClick={() => setShowKM(true)}>
                            <i className='ki-duotone ki-information-3 fs-3'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>
                            Know More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className='fw-normal fs-6 text-gray-600'>
                    Seamlessly create, update, and track articles, ensuring no details are
                    overlooked during the hiring process.
                  </p>
                  {refresh ? (
                    <div className='card h-250px'>
                      <div className='m-auto d-flex flex-column align-items-center'>
                        <div className='spinner-border spinner-primary mr-15'></div>
                      </div>
                    </div>
                  ) : (
                    <div className='card py-2 px-10 mb-5'>
                      <div className='card-header border-0 px-0'>
                        <h3 className='card-title align-items-start flex-column'>
                          <ul className='nav'>
                            <li className='nav-item'>
                              <a
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-success ${
                                  currentStatus === 1 ? 'active' : ''
                                } fw-normal fs-6 px-4 me-1`}
                                data-bs-toggle='tab'
                                onClick={() => setCurrentStatus(1)} // Set status to active
                              >
                                Active
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-danger ${
                                  currentStatus === 0 ? 'active' : ''
                                } fw-normal fs-6 px-4 me-1`}
                                data-bs-toggle='tab'
                                onClick={() => setCurrentStatus(0)} // Set status to inactive
                              >
                                Inactive
                              </a>
                            </li>
                          </ul>
                        </h3>

                        <div className='d-flex'>
                          <div className='d-flex align-items-center position-relative me-4'>
                            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                            <input
                              type='search'
                              id='kt_filter_search'
                              className='form-control form-control-white form-control-sm w-150px ps-9'
                              placeholder='Search'
                              value={inputVal}
                              onChange={(e) => setInputVal(e.target.value)}
                            />
                          </div>

                          <div
                            className='d-flex justify-content-center align-items-center'
                            data-toggle='tooltip'
                            title='Create Article'
                          >
                            <button
                              className='btn btn-sm btn-light-primary me-3'
                              onClick={handleOpenModal}
                            >
                              <KTIcon iconName='plus' className='fs-3' />
                              Create Article
                            </button>
                          </div>

                          <div
                            className='d-flex justify-content-center align-items-center'
                            data-toggle='tooltip'
                            title='Create Article'
                          >
                            <button
                              className='btn btn-sm btn-light-primary me-3'
                              onClick={() => fetchData(true)}
                            >
                              <i className='ki-solid ki-arrows-circle fs-3'></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className='mb-0 mb-xl-8 '>
                        <div className='overflow-auto'>
                          <table className='table table-row-dashed table-row-gray-300 align-middle'>
                            <thead>
                              <tr className='fw-semibold text-muted bg-light rounded'>
                                <th className='min-w-150px default-cursor text-start ps-3'>
                                  Article Title
                                </th>
                                {/* <th className='min-w-140px'>Article Title </th> */}
                                <th className='min-w-120px text-start'>Content Phonetic (ar)</th>

                                <th className='min-w-100px text-start'>Content Phonetic (en)</th>

                                <th className='min-w-120px text-start'>Section</th>

                                <th className='min-w-100px text-start'>Actions</th>
                              </tr>
                            </thead>

                            <tbody {...TableInstance.getTableBodyProps()}>
                              {activePage.length > 0 ? (
                                activePage.map((row) => {
                                  TableInstance.prepareRow(row)
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => (
                                        <td className=' ' {...cell.getCellProps()}>
                                          {cell.render('Cell')}
                                        </td>
                                      ))}
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td className='' colSpan={5}>
                                    <div className='d-flex justify-content-center'>
                                      <p className='text-dark default-cursor fs-6 mt-5'>
                                        {inputVal === '' ? (
                                          intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                        ) : (
                                          <FormattedMessage
                                            id='MSG.NO_DATA_MATCH'
                                            values={{
                                              inputVal: (
                                                <span className='text-primary'>{inputVal}</span>
                                              ),
                                            }}
                                          />
                                        )}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  {data.length > 10 && (
                    <Pagination>
                      <Pagination.First
                        onClick={() => gotoActivePage(0)}
                        disabled={!TableInstance.canPreviousPage}
                      />
                      <Pagination.Prev
                        onClick={TableInstance.previousPage}
                        disabled={!TableInstance.canPreviousPage}
                      />
                      {[...Array(activePageCount)].map((_, i) => (
                        <Pagination.Item
                          key={i}
                          active={i === activePageIndex}
                          onClick={() => gotoActivePage(i)}
                        >
                          {i + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={TableInstance.nextPage}
                        disabled={!TableInstance.canNextPage}
                      />
                      <Pagination.Last
                        onClick={() => gotoActivePage(activePageCount - 1)}
                        disabled={!TableInstance.canNextPage}
                      />
                      <div className='d-flex align-items-center mx-5'>
                        <span className='me-2'>Show</span>
                        <select
                          className='form-select form-select-solid fw-semibold w-75px me-2'
                          value={activePageSize}
                          onChange={(e) => setActivePageSize(Number(e.target.value))}
                        >
                          {pageOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Pagination>
                  )}
                </>
              ) : (
                <div className='d-flex flex-column'>
                  <h3 className='fs-3 fw-semibold'>Manage Articles</h3>

                  <p className=''>
                    Welcome to the Articles Page! Here, you have the tools to seamlessly create and
                    track Articles, ensuring a smooth transition into your organization.
                  </p>
                  <Articlesknowmore />
                  <div
                    className='d-flex justify-content-center align-items-center'
                    data-toggle='tooltip'
                    title='Create Article'
                  >
                    <button className='btn btn-sm btn-light-primary me-3' onClick={handleOpenModal}>
                      <KTIcon iconName='plus' className='fs-3' />
                      Create Article
                    </button>
                  </div>
                </div>
              )}
              <CreateNewArticleModal
                show={showModal}
                onClose={handleCloseModal}
                fetchData={fetchData}
              />
            </>
            <SideDrawer value={id} />
          </>
        )
      }}
    </ArticleContext.Consumer>
  )
}
const ArticlesPage = () => {
  const {execute, loading: isLoading} = usePostApi()
  const intl = useIntl()
  const [offersData, setoffersData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [selectedArticle, setSelectedArticle] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const updateArticleData = (article) => {
    setSelectedArticle(article)
  }
  const [articleId, updatearticleId] = useState('')

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const getOffers = async (show) => {
    if (show) {
      setRefresh(true)
    }
    try {
      const url = `/articles/get/all`
      const formData = {}

      const response = await execute(url, 'POST', formData)

      setoffersData(response.data)
      console.log(response.data)
      setRefresh(false)
    } catch (error) {
      console.error('Error:', error)
      setRefresh(false)
    }
  }

  useEffect(() => {
    getOffers()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Candidate',

        accessor: 'candidate',

        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center ms-3'>
              <div className='d-flex justify-content-start flex-row fw-semibold'>
                <p className='text-dark fw-semibold text-hover-primary default-cursor fs-6 m-0'>
                  {row.original.title_ar}
                  <br />
                  {row.original?.title_en && `(${row.original.title_en})`}
                </p>
              </div>
            </div>
          )
        },

        disableSortBy: true,
      },

      {
        Header: 'Interviewed By',

        accessor: 'Interview',

        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6'>
                {row.original.content_ar_phonetic}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'Content Phonetic',
        accessor: 'Content Phonetic',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6'>
                {row.original.content_en_phonetic}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'Interviewed By',

        accessor: 'Interviewe',

        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6'>
                {row.original.section_name_ar}
                <br />
                {row.original?.section_name_en && `(${row.original.section_name_en})`}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <>
              <div className='d-flex flex-row'>
                <ArticleContext.Consumer>
                  {(value) => {
                    const {updateId} = value
                    return (
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateId({
                            id: row.original.article_id,
                            type: 'view_article',
                            title_ar: row.original.title_ar,
                            title_ar_phonetic: row.original.title_ar_phonetic,
                            title_en: row.original.title_en,
                            title_en_phonetic: row.original.title_en_phonetic,
                            section_id: row.original.section_id,
                            category_id: row.original.category_id,
                            sub_category_id: row.original.sub_category_id,
                            content_ar: row.original.content_ar,
                            content_ar_phonetic: row.original.content_ar_phonetic,
                            content_en: row.original.content_en,
                            content_en_phonetic: row.original.content_en_phonetic,
                            media_type: row.original.media_type,
                            media_id: row.original.media_id,
                            voice_ar_type: row.original.voice_ar_type,
                            voice_ar: row.original.voice_ar,
                            voice_en_type: row.original.voice_en_type,
                            voice_en: row.original.voice_en,
                            status: row.original.status,
                            start_ts: row.original.start_ts,
                            end_ts: row.original.end_ts,
                            tags: row.original.tags,
                          })
                        }}
                      >
                        <KTIcon iconName='eye' className='fs-3' />
                      </div>
                    )
                  }}
                </ArticleContext.Consumer>
                <div className='text-start'>
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm  me-1'
                    onClick={() => {
                      handleOpenModal()
                      updateArticleData({
                        articleId: row.original.article_id,
                        title_ar: row.original.title_ar,
                        title_ar_phonetic: row.original.title_ar_phonetic,
                        title_en: row.original.title_en,
                        title_en_phonetic: row.original.title_en_phonetic,
                        section_id: row.original.section_id,
                        category_id: row.original.category_id,
                        sub_category_id: row.original.sub_category_id,
                        content_ar: row.original.content_ar,
                        content_ar_phonetic: row.original.content_ar_phonetic,
                        content_en: row.original.content_en,
                        content_en_phonetic: row.original.content_en_phonetic,
                        media_type: row.original.media_type,
                        media_id: row.original.media_id,
                        voice_ar_type: row.original.voice_ar_type,
                        voice_ar: row.original.voice_ar,
                        voice_en_type: row.original.voice_en_type,
                        voice_en: row.original.voice_en,
                        status: row.original.status,
                        start_ts: row.original.start_ts,
                        end_ts: row.original.end_ts,
                        tags: row.original.tags,
                      })
                    }}
                  >
                    <KTIcon iconName='pencil' className='fs-3' />
                  </button>
                </div>
              </div>
            </>
          )
        },
      },
    ],

    []
  )

  return (
    <>
      <DataTable
        columns={columns}
        data={offersData ? offersData : []}
        loading={isLoading}
        fetchData={getOffers}
        refresh={refresh}
      />
      <EditArticleModal show={showModal} onClose={handleCloseModal} articleData={selectedArticle} />
    </>
  )
}

export default ArticlesPage
