/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../helpers'

const ToolbarSaas: FC = () => {
  const [progress, setProgress] = useState<string>('1')
  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  return <div className='d-flex align-items-center gap-2'></div>
}

export {ToolbarSaas}
