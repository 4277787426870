import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Outlet} from 'react-router-dom'
import {useAuth} from '../../auth'

function Card() {
  const location = useLocation()
  const {currentUser} = useAuth()
  return (
    <>
      <div className='card mb-4 mb-xl-6'>
        <div className='card-body pt-8 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='me-4'>
              <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
                <img
                  style={{height: '120px', width: '120px'}}
                  src={toAbsoluteUrl('/media/custom_assets/heritage-favicon.png')}
                  alt='profile'
                />
              </div>
            </div>
            <div className='d-flex flex-column justify-content-between'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <p className='text-gray-800 text-hover-primary fs-3 fw-semibold me-1 default-cursor mb-0'>
                      {process.env.REACT_APP_APP_NAME}
                    </p>
                  </div>
                  <div className='d-flex flex-wrap fw-normal fs-6 pe-2'>
                    <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 default-cursor'>
                      <KTIcon iconName='badge' className='fs-6 me-1' />
                      {currentUser?.admin_name}
                    </p>
                    <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 default-cursor'>
                      <KTIcon iconName='badge' className='fs-6 me-1' />
                      {currentUser?.email}
                    </p>
                  </div>
                  <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 default-cursor'>
                    <KTIcon iconName='external-drive' className='fs-6 me-1' />
                    Test
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-semibold flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={`fs-6 nav-link text-active-primary me-6 ${
                    location.pathname === '/media/image' || location.pathname === '/media'
                      ? 'active'
                      : ''
                  }`}
                  to={`/media/image`}
                >
                  Image
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={`fs-6 nav-link text-active-primary me-6 ${
                    location.pathname === '/media/audio' ? 'active' : ''
                  }`}
                  to={`/media/audio`}
                >
                  Audio
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={`fs-6 nav-link text-active-primary me-6 ${
                    location.pathname === '/media/video' ? 'active' : ''
                  }`}
                  to={`/media/video`}
                >
                  Video
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={`fs-6 nav-link text-active-primary me-6 ${
                    location.pathname === '/media/gallery-slider' ? 'active' : ''
                  }`}
                  to={`/media/gallery-slider`}
                >
                  Gallery/Slider
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default Card
