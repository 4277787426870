import {useEffect, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTIcon} from '../../../../_metronic/helpers'
import usePostApi from '../../custom_hooks/usePostApi'
import clsx from 'clsx'
import {useThemeMode} from '../../../../_metronic/partials'
import {useIntl} from 'react-intl'
import React from 'react'
import {useParams} from 'react-router-dom'
import {UseToast} from '../../useToast'
import Select, {components} from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import AddNewAttachment from '../../media/components/image/AddNewAttachment'
import {CreateSubCategory2} from './CreateSubCategory2'

import {CreateCategory2} from './CreateCategory2'

const registrationSchema = Yup.object().shape({
  section_id: Yup.mixed()
    .test(
      'is-array-or-string',
      'Section is Required',
      (value) => Array.isArray(value) || typeof value === 'string'
    )
    .required('Section is Required'),
  // category_id: Yup.array().required('Category is Required'),
  category_id: Yup.mixed()
    .test(
      'is-array-or-string',
      'Category is Required',
      (value) => Array.isArray(value) || typeof value === 'string'
    )
    .required('Category is Required'),

  sub_category_id: Yup.string().required('Sub Category is Required'),
  media_id: Yup.string().required('Media is Required'),
  title_ar: Yup.string().required('Title is Required.'),
  title_ar_phonetic: Yup.string().required('Title phonetic (Arabic) is Required.'),

  media_type: Yup.string().required('Media type is Required'),
})

function CreateNewArticle({closeModal, fetchData}) {
  const [section, setsection] = useState([])
  const [category, setcategory] = useState([])
  const [subcategory, setsubcategory] = useState([])
  const [mediatype, setmediatype] = useState([])
  const [mediaid, setmediaid] = useState([])
  const [mediaidoption, setmediaidoption] = useState([])
  const [sectionoption, setsectionoption] = useState([])
  const [categoryoption, setcategoryoption] = useState([])
  const [subcategoryoption, setsubcategoryoption] = useState([])
  const [mediatypeoption, setmediatypeoption] = useState([])

  const getSections = async () => {
    try {
      const url = `/section/get/all`
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.section_id,
        label: `${each.section_name_ar} - ${each.section_name_en}`,
      }))
      setsection(options)

      if (options.length === 1) {
        setsectionoption(options[0].value)
      } else {
        setsectionoption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getSections()
  }, [])

  const getCategory = async () => {
    try {
      const url = `/category/get/all`
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.category_id,
        label: `${each.category_name_ar} - ${each.category_name_en}`,
      }))
      setcategory(options)

      if (options.length === 1) {
        setcategoryoption(options[0].value)
      } else {
        setcategoryoption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getCategory()
  }, [])

  const getMediatype = async () => {
    try {
      const url = `/media/active/get`
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.media_type,
        label: each.media_type,
      }))
      setmediatype(options)

      if (options.length === 1) {
        setmediatypeoption(options[0].value)
      } else {
        setmediatypeoption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getMediatype()
  }, [])

  const getMedia = async () => {
    try {
      const url = `/media/active/get`
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.media_id,
        label: `${each.media_title_ar} - ${each.media_title_en}`,
      }))
      setmediaid(options)

      if (options.length === 1) {
        setmediaidoption(options[0].value)
      } else {
        setmediaidoption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getMedia()
  }, [])

  const getsubCategory = async () => {
    try {
      const url = `/subcategory/get/all`
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.sub_category_id,
        label: `${each.category_name_ar} - ${each.category_name_en}`,
      }))
      setsubcategory(options)

      if (options.length === 1) {
        setsubcategoryoption(options[0].value)
      } else {
        setsubcategoryoption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getsubCategory()
  }, [])

  const fetchingMissedData = async () => {
    await Promise.all([getMedia(), getSections(), getCategory(), getsubCategory()])
  }

  const [data, setData] = useState({
    title_ar: '',
    title_ar_phonetic: '',
    title_en: '',
    title_en_phonetic: '',
    section_id: sectionoption || '',
    category_id: categoryoption || '',
    sub_category_id: subcategoryoption || '',
    content_ar: '',
    content_ar_phonetic: '',
    content_en: '',
    content_en_phonetic: '',
    media_type: '',
    media_id: mediaidoption || '',
    voice_ar_type: '',
    voice_ar: '',
    voice_en_type: '',
    voice_en: '',
    status: 1,
    start_ts: '',
    end_ts: '',
    tags: '',
  })

  const intl = useIntl()
  const {id} = useParams()

  const {execute, postLoading} = usePostApi()
  const [currentStep, setCurrentStep] = useState(0)

  const set2ndIndex = () => {
    setCurrentStep(1)
  }

  function formatDateToMySQL(dateString) {
    const date = new Date(dateString)

    const pad = (num) => (num < 10 ? `0${num}` : num) // Padding function for single-digit numbers

    // Formatting the date components
    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1) // Months are zero-indexed
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())
    const seconds = pad(date.getSeconds())
    const milliseconds = String(date.getMilliseconds()).padStart(6, '0') // Ensure 6-digit precision

    // Construct the formatted string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`

    return formattedDate
  }
  const handleSubmit = async () => {
    try {
      const url = `/articles/create`

      const formData = new FormData()
      formData.append('title_ar', data.title_ar)

      formData.append('title_ar_phonetic', data.title_ar_phonetic)
      formData.append('title_en', data.title_en ? data.title_en : '')
      formData.append('title_en_phonetic', data.title_en_phonetic ? data.title_en_phonetic : '')
      formData.append('section_id', data.section_id)

      formData.append('category_id', data.category_id)
      formData.append('sub_category_id', data.sub_category_id)
      formData.append('content_ar', data.content_ar)
      formData.append('content_ar_phonetic', data.content_ar_phonetic)
      formData.append('content_en', data.content_en ? data.content_en : '')
      formData.append(
        'content_en_phonetic',
        data.content_en_phonetic ? data.content_en_phonetic : ''
      )

      formData.append('media_type', data.media_type)
      formData.append('media_id', data.media_id)
      formData.append('status', 1)
      formData.append('voice_ar_type', data.voice_ar_type)
      formData.append('voice_ar', data.media_id)
      formData.append('voice_en_type', data.voice_en_type)
      formData.append('voice_en', data.media_id)
      formData.append('start_ts', formatDateToMySQL(data.start_ts))
      formData.append('end_ts', formatDateToMySQL(data.end_ts))
      formData.append('tags', data.tags)
      const response = await execute(url, 'POST', formData)

      setData({
        title_ar: '',
        title_ar_phonetic: '',
        title_en: '',
        title_en_phonetic: '',
        section_id: '',
        category_id: '',
        sub_category_id: '',
        content_ar: '',
        content_ar_phonetic: '',
        content_en: '',
        content_en_phonetic: '',
        media_type: '',
        media_id: '',
        voice_ar_type: '',
        voice_ar: '',
        voice_en_type: '',
        voice_en: '',
        status: 1,
        start_ts: '',
        end_ts: '',
      })

      closeModal()
      fetchData()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const steps = [
    <Step1
      set2ndIndex={set2ndIndex}
      data={data}
      setData={setData}
      sectionsData={section}
      categoryData={category}
      subcategorydata={subcategory}
      mediatypedata={mediatype}
      fetchsections={getSections}
      fetchcategory={getCategory}
      fetchsubcategory={getsubCategory}
      fetchmedia={getMedia}
      sectionOption={sectionoption}
      mediatypeOption={mediatypeoption}
      categoryoption={categoryoption}
      subcategoryoption={subcategoryoption}
      mediaiddata={mediaid}
      mediaidoption={mediaidoption}
    />,
    <Step2 data={data} setData={setData} handleSubmit={handleSubmit} />,
  ]

  const nextStep = () => {
    if (currentStep === 1) {
      const step2Btn = document.getElementById('step2_submit_btn')
      if (step2Btn) {
        step2Btn.click()
      }
    }
    if (currentStep === 0) {
      const step1Btn = document.getElementById('step1_submit_btn')
      if (step1Btn) {
        step1Btn.click()
      }
    }
  }

  // }
  const prevStep = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  return (
    <>
      <div className='card py-0 w-100'>
        <div className='w-100 m-auto min-h-300px'>
          <div className='d-flex justify-content-between mb-10'>
            <h4 className='fw-semibold'>Create New Article</h4>
            <button
              type='button'
              className='d-block btn btn-sm btn-icon btn-light'
              onClick={closeModal}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>

          <div className='w-100 row m-0'>
            <div className='col-lg-5 p-0'>
              <div className=''>
                <div className='d-flex'>
                  <div
                    style={{borderRadius: '10px'}}
                    className={clsx(
                      'w-40px h-40px btn d-flex justify-content-center align-items-center',
                      {
                        'btn-primary': currentStep === 0,
                        'btn-light-primary': currentStep !== 0,
                      }
                    )}
                  >
                    {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                  </div>
                  <div className='ms-5'>
                    <h6
                      className={clsx('mb-0 text-muted', {
                        'text-muted': currentStep >= 0,
                      })}
                    >
                      Step 1
                    </h6>
                    <p
                      className={clsx('fs-6 text-muted mb-0', {
                        'text-muted': currentStep >= 0,
                      })}
                    >
                      Article Details
                    </p>
                  </div>
                </div>
                <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                <div className='d-flex'>
                  <div
                    style={{borderRadius: '10px'}}
                    className={clsx(
                      'w-40px h-40px btn d-flex justify-content-center align-items-center',
                      {
                        'btn-primary': currentStep === 1,
                        'btn-light-primary': currentStep !== 1,
                      }
                    )}
                  >
                    {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                  </div>
                  <div className='ms-5 '>
                    <h6
                      className={clsx('mb-0 text-muted', {
                        'text-muted': currentStep >= 1,
                      })}
                    >
                      Step 2
                    </h6>
                    <p
                      className={clsx('fs-6 text-muted', {
                        'text-muted': currentStep >= 1,
                      })}
                    >
                      Media and Details
                    </p>
                  </div>
                </div>
                {/* Add more steps here */}
              </div>
            </div>
            <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
          </div>

          <div className='w-100 d-flex flex-stack mt-5'>
            <div className='mr-2'>
              {currentStep > 0 && (
                <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              )}
            </div>

            <div>
              <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
                {currentStep === steps.length - 1 ? (
                  <>
                    {postLoading && (
                      <div
                        className='spinner-border spinner-primary mr-15'
                        style={{height: '12px', width: '12px'}}
                      ></div>
                    )}
                    Create Article
                  </>
                ) : (
                  'Continue'
                )}
                {/* {currentStep < steps.length - 1 ? 'Continue' : 'Create POD'} */}
                <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Step1 = (props) => {
  const onSubmit = (values) => {
    props.setData({...values})
    props.set2ndIndex()
  }

  const {execute} = usePostApi()
  const intl = useIntl()
  const {mode} = useThemeMode()

  const sections = props.sectionsData
  const category = props.categoryData
  const subcategory = props.subcategorydata
  const media = props.mediatypedata

  const mediaid = props.mediaiddata
  const [showKM, setShowKM] = useState(false)
  const [locale, setLocale] = React.useState('en')
  const [loading, setLoading] = useState(true)
  const sectionOption = props.sectionOption
  const [showCreateLocation, setShowCreateLocation] = useState(false)
  const categoryOption = props.categoryOption
  const subcategoryoption = props.subcategoryoption
  const mediaidOption = props.mediaidOption
  const [categories, setcategory] = useState([])
  const [subcategories, setsubcategory] = useState([])
  const [categoriesoption, setcategoriesoption] = useState([])
  const [subcategoriesoption, setsubcategoriesoption] = useState([])
  const [selectedSectionId, setSelectedSectionId] = useState('') // State for selected section ID
  const [selectedCategoryId, setSelectedCategoryId] = useState('')
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false)
  const [showAddMediaModal, setShowAddMediaModal] = useState(false)
  const [showAddSubCategoryModal, setShowAddSubCategoryModal] = useState(false)

  const handleSectionChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('section_id', selectedOption.value)
      setSelectedSectionId(selectedOption.value)
    } else {
      setFieldValue('section_id', '')
      setSelectedSectionId('') // Clear section ID
    }
  }

  // Handle category change
  const handleCategoryChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('category_id', selectedOption.value)
      setSelectedCategoryId(selectedOption.value) // Update state with selected category ID
    } else {
      setFieldValue('category_id', '')
      setSelectedCategoryId('') // Clear category ID
    }
  }

  const handlemediatypeChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('media_id', selectedOption.value)
    } else {
      setFieldValue('media_id', '') // Handle case where no option is selected
    }
  }

  const handlesubcategoryChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('sub_category_id', selectedOption.value)
    } else {
      setFieldValue('sub_category_id', '') // Handle case where no option is selected
    }
  }

  const getCategory = async () => {
    try {
      const url = `/category/section_id/get`
      const formData = {
        section_id: selectedSectionId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.category_id,
        label: `${each.category_name_ar} - ${each.category_name_en}`,
      }))
      setcategory(options)

      if (options.length === 1) {
        setcategoriesoption(options[0].value)
      } else {
        setcategoriesoption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getCategory()
  }, [])

  const getsubCategory = async () => {
    try {
      const url = `/subcategory/get/category_id`
      const formData = {
        category_id: selectedCategoryId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.sub_category_id,
        label: `${each.category_name_ar} - ${each.category_name_en}`,
      }))
      setsubcategory(options)

      if (options.length === 1) {
        setsubcategoriesoption(options[0].value)
      } else {
        setsubcategoriesoption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getsubCategory()
  }, [])

  useEffect(() => {
    if (selectedSectionId) {
      getCategory()
    }
  }, [selectedSectionId]) // Trigger when selectedSectionId changes

  // Fetch subcategories when category_id changes
  useEffect(() => {
    if (selectedCategoryId) {
      getsubCategory()
    }
  }, [selectedCategoryId])
  return (
    <>
      <CreateCategory2
        showModal={showAddCategoryModal}
        setShowModal={setShowAddCategoryModal}
        sectionId={selectedSectionId}
        categoryFetchData={getCategory}
      />
      <AddNewAttachment showModal={showAddMediaModal} setShowModal={setShowAddMediaModal} />
      <CreateSubCategory2
        showModal={showAddSubCategoryModal}
        setShowModal={setShowAddSubCategoryModal}
        categoryId={selectedCategoryId}
        subCategoryFetchData={getsubCategory}
      />
      <Formik
        validationSchema={registrationSchema}
        initialValues={{
          ...props.data,
          section_id: sectionOption || props.data.section_id,
          category_id: categoriesoption || props.data.category_id,
          sub_category_id: subcategoriesoption || props.sub_category_id,
          media_id: mediaidOption || props.media_id,
        }}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({setFieldValue, values, handleChange}) => {
          return (
            <Form className=''>
              <>
                <div className='d-flex'>
                  <div className='w-100 mb-5 me-2'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Select Section</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select Section'
                      ></i>
                    </label>
                    <Select
                      value={sections.find((option) => option.value === values.section_id)}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          color: '#ffffff',
                          borderWidth: '0px',
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        input: (baseStyles) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                        }),
                        option: (baseStyles, {isFocused, isSelected}) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? '#C2C2C2'
                            : isFocused
                            ? '#e0e0e0'
                            : baseStyles.backgroundColor,
                          color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        backgroundColor: '#000000',
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(80, 107, 236, 0.5)',
                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        },
                      })}
                      className='react-select-container'
                      classNamePrefix='react-select'
                      options={sections}
                      isSearchable
                      placeholder='Select Section'
                      onChange={(val) => handleSectionChange(setFieldValue, val)}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='section_id' />
                    </div>
                  </div>
                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Select Category</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select Category'
                      ></i>
                    </label>
                    <div className='d-flex flex-row flex-fill'>
                      <Select
                        value={categories.find((option) => option.value === values.category_id)}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            color: '#ffffff',
                            borderWidth: '0px',
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          }),
                          singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000',
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                          }),
                          option: (baseStyles, {isFocused, isSelected}) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? '#C2C2C2'
                              : isFocused
                              ? '#e0e0e0'
                              : baseStyles.backgroundColor,
                            color: isSelected
                              ? '#000000'
                              : isFocused
                              ? '#000000'
                              : baseStyles.color,
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          backgroundColor: '#000000',
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(80, 107, 236, 0.5)',
                            primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          },
                        })}
                        className='react-select-container me-1'
                        classNamePrefix='react-select'
                        options={categories}
                        isSearchable
                        placeholder='Select Category'
                        onChange={(val) => handleCategoryChange(setFieldValue, val)}
                      />
                      <button
                        type='button'
                        className='btn btn-icon btn-light btn-sm ms-1'
                        data-bs-toggle='tooltip'
                        onClick={() => setShowAddCategoryModal(true)}
                      >
                        <KTIcon iconName='plus' className='fs-2' />
                      </button>
                    </div>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='category_id' />
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='w-100 mb-4 me-2'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Select Sub Category</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select Sub Category'
                      ></i>
                    </label>
                    <div className='d-flex flex-row flex-fill'>
                      <Select
                        value={subcategories?.find(
                          (option) => option.value === values.sub_category_id
                        )}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            color: '#ffffff',
                            borderWidth: '0px',
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          }),
                          singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000',
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                          }),
                          option: (baseStyles, {isFocused, isSelected}) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? '#C2C2C2'
                              : isFocused
                              ? '#e0e0e0'
                              : baseStyles.backgroundColor,
                            color: isSelected
                              ? '#000000'
                              : isFocused
                              ? '#000000'
                              : baseStyles.color,
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          backgroundColor: '#000000',
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(80, 107, 236, 0.5)',
                            primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          },
                        })}
                        className='react-select-container me-8'
                        classNamePrefix='react-select'
                        options={subcategories}
                        isSearchable
                        placeholder='Select Sub Category'
                        onChange={(val) => handlesubcategoryChange(setFieldValue, val)}
                      />

                      <button
                        type='button'
                        className='btn btn-icon btn-light btn-sm ms-1'
                        data-bs-toggle='tooltip'
                        onClick={() => setShowAddSubCategoryModal(true)}
                      >
                        <KTIcon iconName='plus' className='fs-2' />
                      </button>
                    </div>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='sub_category_id' />
                    </div>
                  </div>

                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Select Media</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select Media'
                      ></i>
                    </label>
                    <div className='d-flex flex-row flex-fill'>
                      <Select
                        value={mediaid.find((option) => option.value === values.media_id)}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            color: '#ffffff',
                            borderWidth: '0px',
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          }),
                          singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000',
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                          }),
                          option: (baseStyles, {isFocused, isSelected}) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? '#C2C2C2'
                              : isFocused
                              ? '#e0e0e0'
                              : baseStyles.backgroundColor,
                            color: isSelected
                              ? '#000000'
                              : isFocused
                              ? '#000000'
                              : baseStyles.color,
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          backgroundColor: '#000000',
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(80, 107, 236, 0.5)',
                            primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          },
                        })}
                        className='react-select-container me-6'
                        classNamePrefix='react-select'
                        options={mediaid}
                        isSearchable
                        placeholder='Select Media'
                        onChange={(val) => handlemediatypeChange(setFieldValue, val)}
                      />
                      <button
                        type='button'
                        className='btn btn-icon btn-light btn-sm ms-1'
                        data-bs-toggle='tooltip'
                        title='upload media'
                        onClick={() => setShowAddMediaModal(true)}
                      >
                        <KTIcon iconName='plus' className='fs-2' />
                      </button>
                    </div>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='media_id' />
                    </div>
                    {/* <button
                        type='button'
                        className='btn btn-icon btn-light btn-sm ms-1'
                        data-bs-toggle='tooltip'
                        title='upload media'
                        onClick={() => setShowAddMediaModal(true)}
                      >
                        <KTIcon iconName='plus' className='fs-2' />
                      </button> */}
                  </div>
                </div>

                <div className='d-flex'>
                  <div className='w-100 mb-5 me-2'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Title (Arabic)</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Enter title (ar)'
                      ></i>
                    </label>
                    <Field
                      type='text'
                      name='title_ar'
                      className='form-control form-control-solid'
                      placeholder='أدخل العنوان
                     '
                      dir='rtl'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='title_ar' />
                    </div>
                  </div>

                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className=''>Title (English)</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Enter title (en)'
                      ></i>
                    </label>
                    <Field
                      type='text'
                      name='title_en'
                      className='form-control form-control-solid'
                      placeholder='Enter title'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='title_en' />
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='w-100 mb-5 me-2'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Title phonetic (Arabic)</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Enter title (ar)'
                      ></i>
                    </label>
                    <Field
                      name='title_ar_phonetic'
                      className='form-control form-control-solid'
                      placeholder='أدخل العنوان الصوتي
                        '
                      dir='rtl'
                      type='text'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='title_ar_phonetic' />
                    </div>
                  </div>

                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className=''>Title phonetic (English)</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Enter title (en)'
                      ></i>
                    </label>
                    <Field
                      type='text'
                      name='title_en_phonetic'
                      className='form-control form-control-solid'
                      placeholder='Enter title'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='title_en_phonetic' />
                    </div>
                  </div>
                </div>

                <div className='w-100 mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Media type</span>
                    <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip'></i>
                  </label>

                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderMale'
                      name='media_type'
                      value='A'
                      checked={values.media_type === 'A'} // Check if the value is 'M' (Male)
                      onChange={handleChange} // Handle change and update Formik state
                    />
                    <label className='form-check-label fs-6' htmlFor='genderMale'>
                      Audio
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderFemale'
                      name='media_type'
                      value='V'
                      checked={values.media_type === 'V'} // Check if the value is 'F' (Female)
                      onChange={handleChange} // Handle change and update Formik state
                    />
                    <label className='form-check-label fs-6' htmlFor='genderFemale'>
                      Video
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderOthers'
                      name='media_type'
                      value='P'
                      checked={values.media_type === 'P'} // Check if the value is 'O' (Others)
                      onChange={handleChange} // Handle change and update Formik state
                    />
                    <label className='form-check-label fs-6' htmlFor='genderOthers'>
                      Picture
                    </label>
                  </div>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='media_type' />
                  </div>
                </div>

                <div className='d-none d-flex justify-content-end'>
                  <div>
                    <button type='submit' className='btn btn-sm btn-light me-2'>
                      Close
                    </button>
                    <button
                      type='submit'
                      className='btn btn-sm btn-light-primary ms-2'
                      id='step1_submit_btn'
                    >
                      ADD
                    </button>
                  </div>
                </div>
              </>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const step2Schema = Yup.object().shape({
  // content_en: Yup.string().required('Content is Required.'),
  // content_en_phonetic: Yup.string().required('Content phonetic(en) is Required.'),
  content_ar: Yup.string().required('Content is Required.'),
  content_ar_phonetic: Yup.string().required('Content phonetic (Arabic) is Required.'),
  voice_en_type: Yup.string().required('Voice type (English) is Required.'),
  voice_ar_type: Yup.string().required('Voice type (Arabic) is Required.'),
  start_ts: Yup.string().required('Start Date is Required.'),
  end_ts: Yup.string().required('End Date is Required.'),
})

const Step2 = ({data, setData, handleSubmit, setImgData}) => {
  const [initialValues, setInitialValues] = useState(data)
  const {mode} = useThemeMode()
  const [locale, setLocale] = React.useState('en')
  const onSubmit = (values) => {
    setData({...data, ...values})

    handleSubmit()
  }

  const updateData = (key, val) => {
    setData({...data, [key]: val})
  }

  return (
    <Formik
      validationSchema={step2Schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({setFieldValue, values, handleChange}) => {
        return (
          <Form className='w-100'>
            {/* <div className='d-flex'>
              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Title (ar)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter title (ar)'
                  ></i>
                </label>
                <Field
                  type='text'
                  name='title_ar'
                  className='form-control form-control-solid'
                  placeholder='Enter title'
                  onChange={(e) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('title_ar', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='title_ar' />
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Title phonetic (ar)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter title (ar)'
                  ></i>
                </label>
                <Field
                  type='text'
                  name='title_ar_phonetic'
                  className='form-control form-control-solid'
                  placeholder='Enter title'
                  onChange={(e) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('title_ar_phonetic', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='title_ar_phonetic' />
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Title (en)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter title (en)'
                  ></i>
                </label>
                <Field
                  type='text'
                  name='title_en'
                  className='form-control form-control-solid'
                  placeholder='Enter title'
                  onChange={(e) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('title_en', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='title_en' />
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Title phonetic (en)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter title (en)'
                  ></i>
                </label>
                <Field
                  type='text'
                  name='title_en_phonetic'
                  className='form-control form-control-solid'
                  placeholder='Enter title'
                  onChange={(e) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('title_en_phonetic', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='title_en_phonetic' />
                </div>
              </div>
            </div> */}
            <div className='d-flex'>
              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Content (Arabic)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter Content (ar)'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_ar'
                  className='form-control form-control-solid'
                  placeholder='أدخل المحتوى'
                  onChange={(e) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('content_ar', e.target.value)
                  }}
                  dir='rtl'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_ar' />
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Content (English)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter Content (en)'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_en'
                  className='form-control form-control-solid'
                  placeholder='Enter Content (en)'
                  onChange={(e) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('content_en', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_en' />
                </div>
              </div>
            </div>

            <div className='d-flex'>
              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Content Phonetic (Arabic)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter Content (ar)'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_ar_phonetic'
                  className='form-control form-control-solid'
                  placeholder='أدخل المحتوى الصوتي'
                  onChange={(e) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('content_ar_phonetic', e.target.value)
                  }}
                  dir='rtl'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_ar_phonetic' />
                </div>
              </div>
              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Content Phonetic (English)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter Content phonetic (en)'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_en_phonetic'
                  className='form-control form-control-solid'
                  placeholder='Enter Content phonetic'
                  onChange={(e) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('content_en_phonetic', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_en_phonetic' />
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Created Date</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Select Date and time'
                  ></i>
                </label>
                <Field name='start_ts'>
                  {({form, field}) => {
                    const {setFieldValue} = form
                    const {value} = field

                    const handleChange = (date) => {
                      setFieldValue('start_ts', date) // Set the date directly
                      updateData('start_ts', date) // Use the correct key to update data
                    }

                    return (
                      <div
                        className={`react-datepicker-fullwidth-wrapper test ${
                          mode === 'dark' ? 'dark-mode' : 'light-mode'
                        }`}
                      >
                        <DatePicker
                          selected={value}
                          minDate={new Date()}
                          showYearDropdown
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          showTimeSelect
                          timeFormat='HH:mm'
                          timeIntervals={15}
                          dateFormat='dd/MM/yyyy h:mm aa'
                          onChange={handleChange}
                          placeholderText='Select Date and Time'
                          className='form-control form-control-solid'
                          popperPlacement='bottom'
                          locale={locale}
                        />
                      </div>
                    )
                  }}
                </Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='start_ts' />
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>End Date</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Select Date and time'
                  ></i>
                </label>
                <Field name='end_ts'>
                  {({form, field}) => {
                    const {setFieldValue} = form
                    const {value} = field
                    const createdDate = form.values.start_ts
                    const handleChange = (date) => {
                      setFieldValue('end_ts', date) // Set the date directly
                      updateData('end_ts', date) // Use the correct key to update data
                    }

                    return (
                      <div
                        className={`react-datepicker-fullwidth-wrapper test ${
                          mode === 'dark' ? 'dark-mode' : 'light-mode'
                        }`}
                      >
                        <DatePicker
                          selected={value}
                          minDate={createdDate || new Date()}
                          showYearDropdown
                          scrollableYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          showTimeSelect
                          timeFormat='HH:mm'
                          timeIntervals={15}
                          dateFormat='dd/MM/yyyy h:mm aa'
                          onChange={handleChange}
                          placeholderText='Select Date and Time'
                          className='form-control form-control-solid'
                          popperPlacement='bottom'
                          locale={locale}
                        />
                      </div>
                    )
                  }}
                </Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='end_ts' />
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Voice type(en)</span>
                  <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip'></i>
                </label>

                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='genderMale'
                    name='voice_en_type'
                    value='TTS'
                    checked={values.voice_en_type === 'TTS'} // Check if the value is 'M' (Male)
                    onChange={(e) => {
                      updateData(e.target.name, e.target.value)
                      setFieldValue('voice_en_type', e.target.value)
                    }} // Handle change and update Formik state
                  />
                  <label className='form-check-label fs-6' htmlFor='genderMale'>
                    TTS
                  </label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='genderFemale'
                    name='voice_en_type'
                    value='REC'
                    checked={values.voice_en_type === 'REC'} // Check if the value is 'F' (Female)
                    onChange={(e) => {
                      updateData(e.target.name, e.target.value)
                      setFieldValue('voice_en_type', e.target.value)
                    }} // Handle change and update Formik state
                  />
                  <label className='form-check-label fs-6' htmlFor='genderFemale'>
                    REC
                  </label>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='media_type' />
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Voice type(ar)</span>
                  <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip'></i>
                </label>

                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='genderMale'
                    name='voice_ar_type'
                    value='TTS'
                    checked={values.voice_ar_type === 'TTS'} // Check if the value is 'M' (Male)
                    onChange={(e) => {
                      updateData(e.target.name, e.target.value)
                      setFieldValue('voice_ar_type', e.target.value)
                    }} // // Handle change and update Formik state
                  />
                  <label className='form-check-label fs-6' htmlFor='genderMale'>
                    TTS
                  </label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='genderFemale'
                    name='voice_ar_type'
                    value='REC'
                    checked={values.voice_ar_type === 'REC'} // Check if the value is 'F' (Female)
                    onChange={(e) => {
                      updateData(e.target.name, e.target.value)
                      setFieldValue('voice_ar_type', e.target.value)
                    }} // // Handle change and update Formik state
                  />
                  <label className='form-check-label fs-6' htmlFor='genderFemale'>
                    REC
                  </label>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='voice_ar_type' />
                </div>
              </div>
            </div>

            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className=''>Tags</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter any tag names if you want to specify.'
                ></i>
              </label>
              <Field
                id='tags'
                type='text'
                name='tags'
                className='form-control form-control-solid'
                placeholder="Enter tags separated by comma's"
                onChange={(e) => {
                  updateData(e.target.name, e.target.value)
                  setFieldValue('tags', e.target.value)
                }}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='tags' />
              </div>
            </div>

            <button type='submit' className='d-none btn btn-light-primary' id='step2_submit_btn'>
              Add
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateNewArticle
