import {useState, useEffect} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useLocation, useNavigate} from 'react-router-dom'
import usePostApi from '../../custom_hooks/usePostApi'
import {KTIcon} from '../../../../_metronic/helpers'
import AddNewRule from './AddNewRule'

function RulersMSGDetails() {
  const location = useLocation()
  const navigate = useNavigate()
  const {state}: any = location
  const {execute, loading} = usePostApi()
  const [showEditModal, setShowEditModal] = useState(false)

  const [rulersData, setRulersData]: any = useState({})
  const fetchData = async () => {
    try {
      const url = `/ruler/get`
      const formData = {ruler_id: state?.ruler_id}
      const response = await execute(url, 'POST', formData)
      setRulersData(response?.data[0])
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const GalleryViewBC = [
    {
      title: `Administration`,
      path: `/administration`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `Administration`,
      path: `/administration`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Ruler's Messages`,
      path: `/administration/rulers-message`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `Ruler's Messages`,
      path: `/administration/rulers-message`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `${rulersData?.ruler_title_en || rulersData?.ruler_title_ar}`,
      path: `/administration/rulers-message/details`,
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <div>
      <AddNewRule
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        type='edit'
        data={rulersData}
        loadData={fetchData}
      />
      <PageTitle breadcrumbs={GalleryViewBC}>Ruler's Message Details</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h3 className='fw-semibold fs-3 mb-5'>File Information</h3>
        <button
          className='btn btn-light-primary btn-sm'
          onClick={() => navigate('/administration/rulers-message')}
        >
          <KTIcon iconName='arrow-left' className='fs-2' />
          Back
        </button>
      </div>
      {loading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <div className='d-flex flex-column flex-md-row'>
          <div className='w-100 w-md-50'>
            <img
              src={rulersData?.ruler_photo}
              alt={rulersData?.ruler_title_en || rulersData.ruler_title_ar}
              className='w-100 h-100 rounded'
              style={{maxHeight: '400px'}}
            />
          </div>

          <div className='w-100 w-md-50 ms-md-5 mt-5 mt-md-0'>
            <div className='d-flex align-items-center mb-3'>
              <h3 className='fw-semibold fs-3 me-4'>
                {rulersData.media_title_en || rulersData.media_title_ar}
              </h3>
              <button
                className='btn btn-light-primary btn-sm'
                onClick={() => setShowEditModal(true)}
              >
                <KTIcon iconName='pencil' className='fs-3' />
                Edit
              </button>
            </div>

            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Ruler's Title (Arabic)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{rulersData?.ruler_title_ar || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Ruler's Title (English)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{rulersData?.ruler_title_en || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Ruler's Message (Arabic)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{rulersData?.ruler_message_ar || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Ruler's Message (English)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{rulersData?.ruler_message_en || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Media Title (Arabic)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{rulersData?.media_title_ar || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Media Title (English)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{rulersData?.media_title_en || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Start Date</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{rulersData?.start_dt || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>End Date</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{rulersData?.end_dt || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Created</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{rulersData?.created_ts || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Updated</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{rulersData?.updated_ts || '-'}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RulersMSGDetails
