import React, {useEffect, useMemo, useState} from 'react'
import usePostApi from '../../modules/custom_hooks/usePostApi'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import clsx from 'clsx'
import {InfoCategory} from './InfoCategoty'
import {CreateCategory} from './CreateCategory'
import CategoryMain from './CategoryMain'
import {EditCategory} from './EditCategory'
import {useLocation} from 'react-router-dom'
const sections = [
  {url: '/ancient-jobs/category-subcategory', section_id: 1, section_name: `Ancient Jobs`},
  {
    url: '/ancient-house-communities/category-subcategory',
    section_id: 2,
    section_name: `Ancient House Communities`,
  },
  {url: '/ancient-proverbs/category-subcategory', section_id: 3, section_name: `Proverbs`},
  {url: '/podcasts/category-subcategory', section_id: 4, section_name: `Podcasts`},
  {url: '/dictionary/category-subcategory', section_id: 5, section_name: `Dictionary`},
  {url: '/photo-gallery/category-subcategory', section_id: 6, section_name: `Photo Gallery`},
  {url: '/videos/category-subcategory', section_id: 7, section_name: `Videos`},
]

function getSectionIdFromUrl(url: string) {
  const section = sections.find((section) => section.url === url)
  return section ? section.section_id : null
}
function getSectionNameFromUrl(url: string) {
  const section = sections.find((section) => section.url === url)
  return section ? section?.section_name : null
}

function Category() {
  const location = useLocation()
  const [data, setTableData] = useState([])
  const {execute, loading} = usePostApi()
  const [id, updateId]: any = useState('')
  const [inputVal, setInputVal] = useState('')
  const [popover, setPopover]: any = useState(null)
  const [popoverId, setPopoverId]: any = useState(null)
  const [status, setStatus] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showEditModal, setEditShowModal] = useState(false)
  const [sectionId, setSectionId] = useState<number | null>(null)
  const [sectionName, setSectionName]: any = useState(null)
  const [categoryView, setCategoryView] = useState({
    category_name_en: 'create_new',
    category_id: 0,
  })
  useEffect(() => {
    const currentUrl = location.pathname
    const id = getSectionIdFromUrl(currentUrl)
    const name = getSectionNameFromUrl(currentUrl)
    setCategoryView({
      category_name_en: 'create_new',
      category_id: 0,
    })
    setSectionId(id)
    setSectionName(name)
  }, [location.pathname])

  const handleOpenModal = (category: any) => {
    updateId(category)
    setShowModal(true)
  }
  const handleEditOpenModal = (category: any) => {
    updateId(category)
    setEditShowModal(true)
  }
  const fetchData = async () => {
    if (sectionId === null) return
    try {
      const url = '/category/section_id/get'
      const formData = {section_id: sectionId}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    if (sectionId !== null) {
      fetchData()
    }
  }, [sectionId])

  const handleCategoryClick = (categoryName: any) => {
    setCategoryView(categoryName)
  }

  const hanedleCreateConfigClick = () => {
    setCategoryView({
      category_name_en: 'create_new',
      category_id: 0,
    })
  }

  const ClosePopover = () => {
    setPopover(null)
    setPopoverId(null)
  }
  const filteredData = useMemo(() => {
    return data
      ?.filter((category: any) => (status ? category.status === 1 : category.status === 0))
      ?.filter(
        (category: any) =>
          category.category_name_en?.toLowerCase().includes(inputVal.toLowerCase()) ||
          category.category_name_ar?.toLowerCase().includes(inputVal.toLowerCase())
      )
  }, [data, status, inputVal])
  const openPopover = (category: any) => (
    <Popover placement='top' className='min-w-400px'>
      <InfoCategory props={{category, fetchData, ClosePopover}} />
    </Popover>
  )
  const handlePopoverClickInfo = (e: any, category: any) => {
    e.stopPropagation()
    if (popoverId === category.category_id) {
      ClosePopover()
    } else {
      setPopover('info_category')
      setPopoverId(category.category_id)
    }
  }

  return (
    <div className='row g-5 g-xxl-8'>
      <div className='col-xl-3'>
        <div className='app-navbar-item'>
          {loading ? (
            <div className='card h-100px mt-6 d-flex justify-content-center align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          ) : (
            <div
              className='card mb-4 p-5'
              style={{
                maxHeight: '500px',
                overflowY: 'auto',
              }}
            >
              {data && (
                <div className='menu-item' style={{position: 'sticky', top: 0, zIndex: 10}}>
                  <div
                    className={clsx('menu-link  pe-0 ps-0')}
                    style={{padding: '5px', marginBottom: '0px !important'}}
                  >
                    <div
                      className='btn-group btn-group-sm me-2'
                      role='group'
                      aria-label='Large button group'
                    >
                      <button
                        type='button'
                        className={`btn btn-color-muted fw-normal border active  px-3 ${
                          status ? 'btn-active-light-success' : ''
                        }`}
                        onClick={() => setStatus(true)}
                      >
                        Active
                      </button>
                      <button
                        type='button'
                        className={`btn btn-color-muted fw-normal active border px-3 ${
                          status ? '' : 'btn-active-light-danger'
                        }`}
                        onClick={() => setStatus(false)}
                      >
                        Inactive
                      </button>
                    </div>
                    <div className='d-flex align-items-center position-relative me-0 pe-0'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm ps-9 fw-normal'
                        placeholder='Search'
                        autoComplete='off'
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className='menu-item' style={{position: 'sticky', zIndex: 10}}>
                <div
                  className={clsx(
                    'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold',
                    {
                      active: categoryView.category_id === 0,
                    }
                  )}
                  style={{padding: '10px', marginBottom: '0px !important'}}
                  onClick={hanedleCreateConfigClick}
                >
                  <span className='menu-title fw-semibold'>Create New Category</span>
                  <span
                    className='menu-icon'
                    onClick={() => handleOpenModal({sectionId})}
                    style={{cursor: 'default'}}
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                  </span>
                </div>
              </div>
              <div style={{overflowY: 'auto', maxHeight: '450px'}}>
                {filteredData?.map((category: any, index) => (
                  <div className='menu-item' key={index}>
                    <div
                      className={clsx(
                        `menu-link without-sub btn btn-sm btn-color-muted btn-active ${
                          category.status === 1
                            ? 'btn-active-light-primary'
                            : 'btn-active-light-danger'
                        }  fw-semibold`,
                        {
                          active: categoryView.category_id === category.category_id,
                        }
                      )}
                      style={{padding: '10px', marginBottom: '0px !important'}}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <span
                        className={`menu-title fw-semibold ${
                          category.status === 1 ? '' : 'text-danger'
                        }`}
                      >
                        {category.category_name_ar && category.category_name_en ? (
                          <>
                            {category.category_name_ar} ({category.category_name_en})
                          </>
                        ) : category.category_name_ar ? (
                          category.category_name_ar
                        ) : category.category_name_en ? (
                          category.category_name_en
                        ) : (
                          ''
                        )}
                      </span>
                      <OverlayTrigger
                        trigger='click'
                        placement='top'
                        overlay={openPopover(category)}
                        show={popover === 'info_category' && popoverId === category.category_id}
                      >
                        <span
                          className='menu-icon'
                          onClick={(e) => handlePopoverClickInfo(e, category)}
                          style={{cursor: 'default'}}
                        >
                          <KTIcon iconName='information-4' className='fs-4' />
                        </span>
                      </OverlayTrigger>
                      <span
                        className='menu-icon'
                        onClick={() => handleEditOpenModal({category})}
                        style={{cursor: 'default'}}
                      >
                        <KTIcon iconName='pencil' className='fs-4' />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={'col-xl-9'}>
        <CategoryMain
          props={{categoryView, categoryFetchData: fetchData, sectionId, sectionName: sectionName}}
        />
      </div>
      <CreateCategory
        className='modal-backdrop'
        showModal={showModal}
        setShowModal={setShowModal}
        categoryFetchData={fetchData}
        id={id}
      />
      <EditCategory
        className='modal-backdrop'
        showModal={showEditModal}
        setShowModal={setEditShowModal}
        categoryFetchData={fetchData}
        id={id}
      />
    </div>
  )
}

export default Category
