import {Route, Routes} from 'react-router-dom'
import RulersMessagesTB from './rulers_message/RulersMessagesTB'
import RulersMSGDetails from './rulers_message/RulersMSGDetails'

function AdministrationRoute() {
  return (
    <Routes>
      <Route path='/rulers-message' element={<RulersMessagesTB />} />
      <Route path='/rulers-message/details' element={<RulersMSGDetails />} />
      <Route index element={<RulersMessagesTB />} />
    </Routes>
  )
}

export default AdministrationRoute
