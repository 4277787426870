import {Modal} from 'react-bootstrap'

import {EditArticle} from './EditArticle'

function EditArticleModal(props: any) {
  const handleClose = () => {
    props.onClose()
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static'
    >
      <Modal.Body className='overflow-auto'>
        <EditArticle
          articleID={props.articleId}
          closeModal={props.onClose}
          articledata={props.articleData}
        />
      </Modal.Body>
    </Modal>
  )
}

export default EditArticleModal
