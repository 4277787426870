import {useState, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import usePostApi from '../../../custom_hooks/usePostApi'
import Carousel from 'react-bootstrap/Carousel'
import {KTIcon} from '../../../../../_metronic/helpers'
import UploadGallery from './UploadGallery'
import {PageTitle} from '../../../../../_metronic/layout/core'

function GalleryDetails() {
  const location = useLocation()
  const navigate = useNavigate()
  const {state}: any = location

  const {execute, loading} = usePostApi()
  const [galleryData, setGalleryData]: any = useState([])
  const [metaData, setMetaData]: any = useState({})
  const [metaInfo, setMetaInfo]: any = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const fetchData = async () => {
    try {
      const url = `/media/get`
      const formData = {media_id: state?.media_id}
      const response = await execute(url, 'POST', formData)
      setGalleryData(response?.data[0])
      const parsedMetaData = JSON.parse(response.data[0].metadata)
      setMetaData(parsedMetaData)
      setMetaInfo(JSON.parse(parsedMetaData?.meta_info))
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  function bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

    if (bytes === 0) return 'n/a'

    const i = Math.floor(Math.log(bytes) / Math.log(1024))

    if (i === 0) return `${bytes} ${sizes[i]}`

    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
  }

  const GalleryViewBC = [
    {
      title: `Media`,
      path: `/media`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `Gallery/Slider`,
      path: `/media/gallery-slider`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Gallery/Slider`,
      path: `/media/gallery-slider`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `Gallery/Slider`,
      path: `/media/gallery-slider`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `${galleryData.media_title_en || galleryData.media_desc_ar}`,
      path: `/media/gallery-slider/details`,
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={GalleryViewBC}>Gallery/Slider Details</PageTitle>

      <UploadGallery
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        type='edit'
        data={galleryData}
        loadData={fetchData}
      />
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h3 className='fw-semibold fs-3 mb-5'>File Information</h3>
        <button
          className='btn btn-light-primary btn-sm'
          onClick={() => navigate('/media/gallery-slider')}
        >
          <KTIcon iconName='arrow-left' className='fs-2' />
          Back
        </button>
      </div>

      {loading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <div className='d-flex flex-column flex-md-row'>
          <div className='w-100 w-md-50'>
            <Carousel>
              {metaData.media_ids?.map((image: {id: number; url: string}) => (
                <Carousel.Item key={image.id}>
                  <img
                    className='d-block w-100 h-400px rounded'
                    src={image.url}
                    alt={`Slide ${image.id}`}
                  />
                  {/* <Carousel.Caption>
                <h3>Slide {image.id} label</h3>
                <p>This is the slide with id: {image.id}</p>
              </Carousel.Caption> */}
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className='w-100 w-md-50 ms-md-5 mt-5 mt-md-0'>
            <div className='d-flex align-items-center mb-3'>
              <h3 className='fw-semibold fs-3 me-4'>
                {galleryData?.media_title_en || galleryData.media_title_ar}
              </h3>
              <button
                className='btn btn-light-primary btn-sm'
                onClick={() => setShowEditModal(true)}
              >
                <KTIcon iconName='pencil' className='fs-3' />
                Edit
              </button>
            </div>

            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Title (Arabic)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{galleryData?.media_title_ar || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Title (English)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{galleryData?.media_title_en || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Description (Arabic)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{galleryData?.media_desc_ar || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Description (English)</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{galleryData?.media_desc_en || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Status</label>
              <div className='col-lg-8'>
                <span
                  className={`fw-normal default-cursor badge badge-light-${
                    galleryData.status === 1 ? 'success' : 'danger'
                  }`}
                >
                  {galleryData?.status === 1 ? 'Active' : 'Inactive'}
                </span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Extension</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{metaData?.extension || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Mime</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{metaData?.mime || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>File size</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>
                  {metaData?.size ? bytesToSize(metaData?.size) : '-'}
                </span>
              </div>
            </div>

            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Tags</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{metaInfo?.tags?.join(', ') || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Uploaded on</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{galleryData?.created_ts || '-'}</span>
              </div>
            </div>
            <div className='row mb-2 align-items-center'>
              <label className='col-lg-4 fw-semibold text-muted'>Updated on</label>
              <div className='col-lg-8'>
                <span className='fs-6 default-cursor'>{galleryData?.updated_ts || '-'}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default GalleryDetails
