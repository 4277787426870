import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import usePostApi from '../../modules/custom_hooks/usePostApi'
import {KTIcon} from '../../../_metronic/helpers'
import Select from 'react-select'
import {useEffect, useState} from 'react'
import {useThemeMode} from '../../../_metronic/partials'
import {UseToast} from '../../modules/custom_hooks/useToast'
import {Modal} from 'react-bootstrap'
import AddNewAttachment from '../../modules/media/components/image/AddNewAttachment'
const EditCategory = (props: any) => {
  const {category} = props.id || {}
  const {
    category_name_ar,
    category_name_en,
    category_desc_ar,
    category_desc_en,
    status,
    category_id,
    media_id,
    section_id,
    category_image,
  }: any = category || {}
  const {execute, postLoading} = usePostApi()
  const [showAddModal, setShowAddModal] = useState(false)
  const [mediaOptions, setMediaOptions] = useState([])
  const [mediaName, setMediaData]: any = useState([])
  const {mode} = useThemeMode()
  const validationSchema = Yup.object().shape({
    category_name_arabic: Yup.string().required('Category name is required'),
    category_description_arabic: Yup.string().required('Category description is required'),
    category_image: Yup.string().required('Category image is required'),
  })
  const mediafetchData = async () => {
    try {
      const url = `/media/active/get`
      const formData = {}
      const response = await execute(url, 'POST', formData)
      setMediaData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    mediafetchData()
  }, [])
  useEffect(() => {
    const options: any = (mediaName || []).map((each: any) => ({
      value: each.media_location_url,
      media_id: each.media_id,
      label: `${each.media_title_ar} (${each.media_title_en})`,
      label_ar: each.media_title_ar,
      label_en: each.media_title_en,
    }))
    if (options) {
      setMediaOptions(options)
    }
  }, [mediaName])

  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `/category/update`
    try {
      const requestData = {
        section_id: section_id,
        category_id: category_id,
        media_id: values.media_id,
        category_name_ar: values.category_name_arabic,
        category_name_en: values.category_name_english,
        category_desc_ar: values.category_description_arabic,
        category_desc_en: values.category_description_english,
        category_icon: 'inventory',
        category_image: values.category_image,
        status: values.status ? 1 : 0,
      }
      const response = await execute(url, 'POST', requestData, 'post')
      resetForm()
      props.categoryFetchData()
      props.setShowModal(false)
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  const handleMediaChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('category_image', selectedOption.value)
      setFieldValue('media_id', selectedOption.media_id)
    } else {
      setFieldValue('category_image', '')
      setFieldValue('media_id', '')
    }
  }

  const CustomOption = (props: any) => {
    const {data, innerRef, innerProps} = props
    return (
      <div ref={innerRef} {...innerProps} className='d-flex flex-row m-2'>
        <div className='d-flex'>
          <div className='symbol me-2'>
            <img src={data.value} alt='Brand Logo' style={{width: '35px', height: '35px'}} />
          </div>
        </div>
        <div className='ms-4 d-flex'>
          <div className='row'>
            <p className='text-dark text-hover-primary fs-8 mb-0'>{data.label_ar}</p>
            <p className='text-dark text-hover-primary fs-8 mt-0'>
              {data.label_en ? `(${data.label_en})` : ''}
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Modal
        show={props.showModal}
        onHide={() => props.setShowModal(false)}
        centered
        dialogClassName='custom-modal modal-dialog-centered'
        contentClassName='custom-modal-content'
        style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
      >
        <Modal.Body className='overflow-auto'>
          <div className='card py-0 overflow-auto'>
            <div className='w-100 m-auto'>
              <div className='d-flex justify-content-between mb-6'>
                <h4 className='fw-semibold'>Edit Category</h4>
                <button
                  type='button'
                  className='d-block btn btn-sm btn-icon btn-light'
                  onClick={() => {
                    props.setShowModal(false)
                  }}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </button>
              </div>
              <Formik
                initialValues={{
                  category_name_arabic: '' || category_name_ar,
                  category_name_english: '' || category_name_en,
                  category_description_arabic: '' || category_desc_ar,
                  category_description_english: '' || category_desc_en,
                  category_image: '' || category_image,
                  media_id: '' || media_id,
                  status: '' || status === 1,
                }}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({values, resetForm, setFieldValue}) => {
                  return (
                    <Form>
                      <div className='d-flex flex-column flex-md-row justify-content-between'>
                        <div className='w-100 w-md-50 me-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                          >
                            Category Name (Arabic)
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='floatingInput'
                            placeholder='Enter Category Name'
                            name='category_name_arabic'
                            dir='rtl'
                          />
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='category_name_arabic'
                          />
                        </div>
                        <div className='w-100 w-md-50 ms-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                          >
                            Category Name (English)
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='floatingInput'
                            placeholder='Enter Category Name'
                            name='category_name_english'
                          />
                        </div>
                      </div>
                      <div className='d-flex flex-column flex-md-row justify-content-between'>
                        <div className='w-100 w-md-50 me-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                          >
                            Category Description (Arabic)
                          </label>
                          <Field
                            id='brand_des_arabic'
                            dir='rtl'
                            as='textarea'
                            className={`form-control form-control form-control-solid`}
                            name='category_description_arabic'
                            placeholder='Briefly describe your Category'
                            rows={3}
                          />
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='category_description_arabic'
                          />
                        </div>
                        <div className='w-100 w-md-50 ms-md-2 mb-2'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                          >
                            Category Description (English)
                          </label>
                          <Field
                            id='brand_des_english'
                            as='textarea'
                            className={`form-control form-control form-control-solid`}
                            name='category_description_english'
                            placeholder='Briefly describe your Category'
                            rows={3}
                          />
                        </div>
                      </div>
                      <div className='d-flex flex-column flex-md-row justify-content-between'>
                        <div className='w-100 w-md-50 me-md-2 mb-2'>
                          <label className='fs-6 fw-semibold form-label mb-2 required'>
                            Category Image
                          </label>
                          <div className='d-flex flex-row flex-fill'>
                            <Select
                              value={mediaOptions.find(
                                (option: any) => option.value === values.category_image
                              )}
                              styles={{
                                control: (baseStyles: any) => ({
                                  ...baseStyles,
                                  backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                  color: '#ffffff',
                                  borderWidth: '0px',
                                }),
                                menu: (baseStyles: any) => ({
                                  ...baseStyles,
                                  backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                                }),
                                menuList: (baseStyles: any) => ({
                                  ...baseStyles,
                                  maxHeight: '170px', // Set max height for dropdown options
                                  overflowY: 'auto', // Allow scrolling when maxHeight is reached
                                }),
                                option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                                  ...baseStyles,
                                  backgroundColor: isSelected
                                    ? '#C2C2C2'
                                    : isFocused
                                    ? '#e0e0e0'
                                    : baseStyles.backgroundColor,
                                  color: isSelected
                                    ? '#000000'
                                    : isFocused
                                    ? '#000000'
                                    : baseStyles.color,
                                  minHeight: '40px', // Minimum height for options
                                  display: 'flex',
                                  alignItems: 'center', // Center content vertically
                                }),
                                menuPortal: (baseStyles: any) => ({
                                  ...baseStyles,
                                  zIndex: 9999, // Ensure menuPortal renders above the modal
                                }),
                              }}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 5,
                                colors: {
                                  ...theme.colors,
                                  primary25: 'rgba(80, 107, 236, 0.5)',
                                  primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                },
                              })}
                              className='react-select-container flex-fill'
                              classNamePrefix='react-select'
                              options={mediaOptions}
                              isSearchable
                              placeholder='Select Media'
                              onChange={(val: any) => handleMediaChange(setFieldValue, val)}
                              components={{
                                Option: CustomOption, // Custom option component
                              }}
                              menuPortalTarget={document.body}
                            />

                            <button
                              type='button'
                              className='btn btn-icon btn-light btn-sm ms-1'
                              data-bs-toggle='tooltip'
                              title='upload media'
                              onClick={() => setShowAddModal(true)}
                            >
                              <KTIcon iconName='plus' className='fs-2' />
                            </button>
                          </div>
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='category_image'
                          />
                        </div>
                        <div className='w-100 w-md-50 ms-md-2 mb-2'>
                          <label className='fs-6 fw-semibold form-label mb-2'>Status</label>
                          <div className='form-floating flex-fill'>
                            <div className='form-check form-check-solid form-switch'>
                              <Field
                                className='form-check-input w-40px h-20px'
                                type='checkbox'
                                name='status'
                                id='toggle'
                                style={{
                                  backgroundColor: values.status
                                    ? '#009688'
                                    : mode === 'dark'
                                    ? '#1b1b29'
                                    : '#f4f4f4',
                                }}
                              />
                              <span
                                className={`me-4 fw-semibold ${
                                  values.status ? 'text-success' : 'text-danger'
                                }`}
                              >
                                {values.status ? 'Active' : 'Inactive'}
                              </span>
                              <ErrorMessage component='div' className='text-danger' name='status' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <button
                          type='reset'
                          onClick={() => {
                            handleCancel(resetForm)
                            props.setShowModal(false)
                          }}
                          className='btn btn-sm btn-light me-2'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-sm btn-light-primary'
                          data-kt-menu-dismiss='true'
                        >
                          {postLoading ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            <>
                              <KTIcon iconName='check' className='fs-7' />
                              Save
                            </>
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AddNewAttachment
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        type='add'
        data={{}}
        loadData={mediafetchData}
      />
    </div>
  )
}

export {EditCategory}
