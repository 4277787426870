import React from 'react'
import YouTubeVideos from './VideoComponent' // Adjust the import path as needed
import {PageTitle} from '../../../../../_metronic/layout/core'

const VideoViewBC = [
  {
    title: `Media`,
    path: `/media`,
    isSeparator: false,
    isActive: false,
  },
  {
    title: `Video`,
    path: `/media`,
    isSeparator: true,
    isActive: false,
  },
  {
    title: `Video`,
    path: `/media/video`,
    isSeparator: false,
    isActive: false,
  },
]

function VideoMain() {
  return (
    <>
      <PageTitle breadcrumbs={VideoViewBC}>Videos</PageTitle>
      <div className='App'>
        <YouTubeVideos />
      </div>
    </>
  )
}

export default VideoMain
